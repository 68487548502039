/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Svg from 'components/svg';
import InputText from 'components/inputText';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/button';
import { routePaths } from 'utils/constants';
import { omit } from 'lodash';
import { useMutation } from 'react-query';
import { resetPassword } from 'api/authenApi';
import { toast } from 'react-toastify';
import { Spinner } from 'flowbite-react';
import { verifyPassword } from 'utils/utils';

const ChangePasswordPage = (props: any) => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const location = useLocation();
  const inputRef: any = useRef(null);
  const [step, setStep] = useState('change');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const hookForm = useForm({ mode: 'onChange' }) as any;
  const errors = hookForm.formState.errors;

  useEffect(() => {
    if (!token) navigate(routePaths.DASHBOARD_PAGE);
  });

  const muResetPassword = useMutation('resetPassword', {
    mutationFn: resetPassword,
    onSuccess: () => {
      setStep('finish');
      toast.success(`${t('createPassSuccess')}`);
    },
    onError: () => {
      toast.error(`${t('createPassFailed')}`);
    },
  });

  const onSubmit = (value: any) => muResetPassword.mutate({ token, ...omit(value, 'emailAddress') });

  const svgEye = (name: any, show: any) => (
    <Svg className={errors?.[name]?.message ? `text-red-600` : 'text-gray-500'} name={show ? 'eye-slash' : 'eye'} />
  );
  const checkFocus = () => {
    if (inputRef?.current) {
      inputRef.current.blur();
      inputRef.current.focus();
    }
  };

  return (
    <div className="flex flex-1 bg-tertiaryDark h-screen items-center justify-center" onClick={checkFocus}>
      <div className="w-[440px] px-12 pt-11 pb-6 bg-white rounded-md flex flex-col items-center shadow-lg">
        {step === 'change' && (
          <div className="flex flex-col items-center w-full">
            <Svg name="logo-text" />
            <p className="py-7 text-2xl font-medium">{t('changePassword.title')}?</p>
            <p className="pb-7 text-base text-gray-500 text-center">{t('ResetPassDescription')}</p>
            <div className="w-full">
              <InputText
                hookForm={hookForm}
                rules={{
                  required: t('passwordRequired'),
                  minLength: { value: 8, message: t('min8Char') },
                  validate: (value: any) => verifyPassword(value, t),
                }}
                focus
                placeholder={`${t('changePassword.newPassword')}`}
                name="password"
                isHolderLabel={true}
                type={showPassword ? 'text' : 'password'}
                className="mt-2"
                rightIcon={svgEye('password', showPassword)}
                handleClick={() => setShowPassword(!showPassword)}
              />
              <InputText
                hookForm={hookForm}
                rules={{
                  required: t('confirmPasswordRequired'),
                  validate: (value: any) => value === hookForm.getValues('password') || t('passwordsDontMatch'),
                }}
                focus
                placeholder={`${t('changePassword.newPasswordConfirm')}`}
                name="confirmPassword"
                isHolderLabel={true}
                type={showPasswordConfirm ? 'text' : 'password'}
                className="mt-6"
                rightIcon={svgEye('confirmPassword', showPasswordConfirm)}
                handleClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
              />
              <Button
                color="primary"
                className="font-semibold w-full flex items-center justify-center py-2 mt-8"
                disabled={muResetPassword.isLoading}
                onClick={hookForm.handleSubmit(onSubmit)}
              >
                <span className="text-base">{t('Continue')}</span>
                {muResetPassword.isLoading && <Spinner size="sm" className="ml-2" />}
              </Button>
              <div className="w-full py-4 flex items-center justify-center mt-2 cursor-pointer" onClick={() => navigate(routePaths.DASHBOARD_PAGE)}>
                <span className="text-base font-bold text-primary">{t('Backto')}</span>
              </div>
            </div>
          </div>
        )}
        {step === 'finish' && (
          <div className="flex flex-col items-center w-full pb-6">
            <Svg name="success100" />
            <p className="py-6 text-3xl font-medium text-green-400 text-center">{t('changePasswordSuccess')}</p>
            <p className="text-base text-center">{t('changePasswordSuccessDes')}</p>
            <Button
              color="primary"
              className="font-semibold flex items-center justify-center px-6 py-2 mt-8"
              onClick={() => navigate(routePaths.DASHBOARD_PAGE)}
            >
              <span className="text-base">{t('loginNow')}</span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChangePasswordPage;
