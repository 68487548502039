/* eslint-disable */
import { Route, Routes, Navigate } from 'react-router-dom';
import routes from 'router/routes';
import MainLayout from '../components/layouts/mainLayout';
import { BasicLayout, UpdateProfile, NotFoundPage, DashboardPage } from 'pages';
import { useDispatch, useSelector } from 'react-redux';
import { orgSelector, userSelector } from 'reduxs/selectors';
import { roleName, routePaths } from 'utils/constants';
import { Helmet } from 'react-helmet';
import { checkPermission, checkSubscriptionForever, checkSubscriptionExpired } from 'utils/utils';
import { useEffect, useState } from 'react';
import SubcribeModal from 'components/modal/subcribeModal';
import { getSubscription } from 'api/subscriptionApi';
import SubscriptionCanceledModal from '../components/modal/subscriptionCanceledModal';
import ContactOrgAdminModal from '../components/modal/contactOrgAdminModal';
import SubscriptionPaymentErrorModal from '../components/modal/subscriptionPaymentErrorModal';
import { orgAction, userAction } from '../reduxs/actions';
import SubscriptionErrorModal from '../components/modal/subscriptionErrorModal';
import useUser from '../hooks/useUser';
import LogoutPage from '../pages/authen/logout';
const { DASHBOARD_PAGE, UPDATE_PROFILE } = routePaths;

const Layout = (props: any) => (props.layout === 'MainLayout' ? <MainLayout {...props} /> : <BasicLayout {...props} />);

const ElementLayout = (props: any) => (
  <Layout {...props}>
    <Helmet>
      <title>{props.title ? props.title : 'HEDGE Health'}</title>
      <meta name="description" content={'Description'} />
    </Helmet>
    {props.component ? <props.component type={props.type} permissions={props.permissions} /> : <></>}
  </Layout>
);

const AuthenRouters = () => {
  const dispatch = useDispatch();
  const { userInfo, isShowSubcribe } = useSelector(userSelector);
  const { organizationId } = useSelector(orgSelector);
  const { fetchCurrentUser } = useUser();
  const isUpdate = !!userInfo?.updatedProfile;
  const [isShowSubcribeModal, setIsShowSubcribeModal] = useState(false);
  const [isShowSubscriptionCanceledModal, setIsShowSubscriptionCanceledModal] = useState(false);
  const [isShowSubscriptionPaymentErrorModal, setIsShowSubscriptionPaymentErrorModal] = useState(false);
  const [isShowSubscriptionErrorModal, setIsShowSubscriptionErrorModal] = useState(false);
  const [isShowContactOrgAdminModal, setIsShowContactOrgAdminModal] = useState(false);
  const [subscription, setSubscription] = useState(null);

  const checkSubscription = async () => {
    let isShowPaywall: any = false;
    dispatch(userAction.setIsShowSubcribe(false));
    setIsShowSubscriptionCanceledModal(false);
    setIsShowSubscriptionPaymentErrorModal(false);
    setIsShowSubscriptionErrorModal(false);
    setIsShowContactOrgAdminModal(false);
    if (!isUpdate) {
      return;
    }
    if (userInfo) {
      const userOrg = userInfo?.organizations?.find((org: any) => org.organizationId === organizationId);
      const stripeCustomerId = userOrg?.stripeCustomerId;
      const userRole = userOrg?.role?.displayName;
      const isForever = checkSubscriptionForever(userInfo, organizationId);
      const isExpired = checkSubscriptionExpired(userInfo, organizationId);
      if (!isForever && !isExpired) {
        // Site Admin set stripeSubscriptionExpireDate in future for  this organization
        dispatch(userAction.setIsShowSubcribe(true));
        return;
      }
      let subscriptions;
      if (stripeCustomerId) {
        try {
          subscriptions = await getSubscription(stripeCustomerId);
          setSubscription(subscriptions?.data?.data[0]);
        } catch (e) {
          if (userRole === roleName.ORGANIZATION_ADMIN || userRole === roleName.THERAPIST) {
            setIsShowSubscriptionErrorModal(true);
            dispatch(orgAction.setPaywall(true));
            return;
          }
        }
      }

      if (userRole === roleName.ORGANIZATION_ADMIN) {
        if (!stripeCustomerId) {
          dispatch(userAction.setIsShowSubcribe(true));
          isShowPaywall = true;
        } else {
          if (subscriptions?.data?.data.length === 0) {
            dispatch(userAction.setIsShowSubcribe(true));
            isShowPaywall = true;
          } else {
            if (isExpired && subscriptions?.data?.data[0]?.status === 'canceled') {
              setIsShowSubscriptionCanceledModal(true);
              isShowPaywall = true;
            } else {
              dispatch(userAction.setIsShowSubcribe(false));
              isShowPaywall = false;
            }
          }
        }
      } else if (userRole === roleName.THERAPIST) {
        if (!stripeCustomerId) {
          setIsShowSubscriptionPaymentErrorModal(true);
          isShowPaywall = true;
        } else {
          if (subscriptions?.data?.data.length === 0) {
            setIsShowSubscriptionPaymentErrorModal(true);
            isShowPaywall = true;
          } else {
            if (isExpired && subscriptions?.data?.data[0]?.status === 'canceled') {
              setIsShowContactOrgAdminModal(true);
              isShowPaywall = true;
            } else {
              setIsShowSubscriptionPaymentErrorModal(false);
              isShowPaywall = false;
            }
          }
        }
      }
    }
    dispatch(orgAction.setPaywall(isShowPaywall));
  };

  useEffect(() => {
    checkSubscription();
    const intervalSubscription = setInterval(async () => {
      await fetchCurrentUser();
      checkSubscription();
    }, 60000);
    return () => clearInterval(intervalSubscription);
  }, [userInfo, organizationId]);

  useEffect(() => {
    const isForever = checkSubscriptionForever(userInfo, organizationId);
    const isExpired = checkSubscriptionExpired(userInfo, organizationId);
    if (isShowSubcribe && !isForever && !isExpired) {
      setIsShowSubcribeModal(false);
    } else {
      setIsShowSubcribeModal(isShowSubcribe);
    }
  }, [isShowSubcribe]);

  return (
    <>
      <Routes>
        <Route path={routePaths.LOGOUT_PAGE} element={<LogoutPage />} key={`route-logout`} />
        <Route path={DASHBOARD_PAGE} element={!isUpdate ? <Navigate to={UPDATE_PROFILE} /> : <DashboardPage />} key={`route-dashboard`} />
        <Route path={UPDATE_PROFILE} element={isUpdate ? <Navigate to={DASHBOARD_PAGE} /> : <UpdateProfile />} key={`route-update-profile`} />
        {routes.map((route, index) => {
          const checkPerm = checkPermission(userInfo, route.type, route.permissions, organizationId);
          const element = checkPerm ? <ElementLayout {...route} /> : <Navigate to={DASHBOARD_PAGE} />;
          return <Route path={route.path} element={element} key={`route-${index + 1}`} />;
        })}
        <Route path="*" element={<NotFoundPage />} key={`route-not-found`} />
      </Routes>
      {isShowSubcribeModal && <SubcribeModal openModal={true} />}
      {isShowSubscriptionCanceledModal && checkSubscriptionExpired(userInfo, organizationId) && (
        <SubscriptionCanceledModal openModal={true} subscription={subscription} />
      )}
      {isShowSubscriptionPaymentErrorModal && <SubscriptionPaymentErrorModal openModal={true} subscription={subscription} />}
      {isShowSubscriptionErrorModal && <SubscriptionErrorModal openModal={true} subscription={subscription} />}
      {isShowContactOrgAdminModal && checkSubscriptionExpired(userInfo, organizationId) && (
        <ContactOrgAdminModal openModal={true} subscription={subscription} />
      )}
    </>
  );
};
export default AuthenRouters;
