/* eslint-disable react/jsx-props-no-spreading */
import { Modal, Spinner } from 'flowbite-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/styles.scss';
import CustomModalHeader from 'components/modal/customModalHeader';
import GroupButton from 'components/button/groupButton';
import './confirmDeleteModal.scss';
import { useQuery, useQueryClient } from 'react-query';
import { getExerciseTemplates } from 'api/exerciseTemplateApi';
import Svg from 'components/svg';
import ReactPlayer from 'react-player';
import { isImageType, isVideoType } from 'utils/utils';
import { HiOutlineSearch } from 'react-icons/hi';
import { ExerciseLibraryType, pageCount, searchMaxLength } from 'utils/constants';
import './selectExerciseTemplateModal.scss';
import { getOrganizationExerciseTemplates } from '../../api/organizationExerciseTemplateApi';
import { getSiteExerciseTemplates } from '../../api/siteExerciseTemplateApi';
import debounce from 'lodash/debounce';
import InfiniteScroll from 'react-infinite-scroll-component';

const SelectExerciseTemplateModal = (props: any) => {
  const { openModal, setOpenModal, selectedExerciseTemplate, setSelectedExerciseTemplate } = props;
  const rootRef = useRef<HTMLDivElement>(null);
  const inputRef: any = useRef(null);
  const [t] = useTranslation();
  const [tempSelected, setTemplateSelected] = useState(selectedExerciseTemplate);
  const [exerciseTemplatesData, setExerciseTemplatesData] = useState([]);
  const [organizationExerciseTemplatesData, setOrganizationExerciseTemplatesData] = useState([]);
  const [siteExerciseTemplatesData, setSiteExerciseTemplatesData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [tab, setTab] = useState(ExerciseLibraryType.MY_EXERCISE);
  const modalRef: any = useRef(null);
  const queryClient = useQueryClient();
  const [page, setPage] = useState(0);
  const [organizationPage, setOrganizationPage] = useState(0);
  const [sitePage, setSitePage] = useState(0);
  const [totalEntitiesMyLibirary, setTotalEntitiesMyLibirary] = useState(0);
  const [totalEntitiesOrgLibrary, setTotalEntitiesOrgLibrary] = useState(0);
  const [totalEntitiesSite, setTotalEntitiesSite] = useState(0);
  const scollList = document.querySelector('.js-scroll-list');
  if (scollList) {
    scollList.scrollTo(0, 0);
  }

  const { data: dataExerciseTemplates } = useQuery(
    ['getExerciseTemplate', page, searchValue],
    () =>
      getExerciseTemplates({
        page: page,
        limit: pageCount,
        searchQuery: searchValue,
      }),
    {
      staleTime: Infinity,
      enabled: tab === ExerciseLibraryType.MY_EXERCISE,
    }
  );

  const { data: dataOrganizationExerciseTempates } = useQuery(
    ['getOrganizationExerciseTemplate', organizationPage, searchValue],
    () =>
      getOrganizationExerciseTemplates({
        page: organizationPage,
        limit: pageCount,
        searchQuery: searchValue,
      }),
    {
      staleTime: Infinity,
      enabled: tab === ExerciseLibraryType.ORGANIZATION_EXERCISE,
    }
  );

  const { data: dataSiteExerciseTemplates } = useQuery(
    ['getSiteExerciseTemplate', sitePage, searchValue],
    () =>
      getSiteExerciseTemplates({
        page: sitePage,
        limit: pageCount,
        searchQuery: searchValue,
      }),
    {
      staleTime: Infinity,
      enabled: tab === ExerciseLibraryType.SITE_EXERCISE,
    }
  );

  const closeModal = () => {
    setOpenModal(false);
  };

  const handleChangeTab = async (tab: any) => {
    setTotalEntitiesMyLibirary(dataExerciseTemplates?.data.totalEntities);
    setTotalEntitiesOrgLibrary(dataOrganizationExerciseTempates?.data.totalEntities);
    setTotalEntitiesSite(dataSiteExerciseTemplates?.data.totalEntities);
    setTab(tab);
  };

  const handleSelectTemplate = (template: any) => {
    setTemplateSelected(template);
  };

  const handleSave = () => {
    setSelectedExerciseTemplate(tempSelected);
    closeModal();
  };

  const handleSearch = async (value: any) => {
    setSearchValue(value);
  };

  const handleChange = (e: any) => {
    setOrganizationExerciseTemplatesData([])
    setExerciseTemplatesData([])
    setSiteExerciseTemplatesData([])
    handleSearch(e.target.value);
  };
  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 700);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  useEffect(() => {
    if (dataExerciseTemplates && tab === ExerciseLibraryType.MY_EXERCISE) {
      setTotalEntitiesMyLibirary(dataExerciseTemplates?.data?.totalEntities)
      const appendData: any = [...exerciseTemplatesData, ...dataExerciseTemplates?.data?.entities];
      setExerciseTemplatesData(appendData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataExerciseTemplates, searchValue])

  useEffect(() => {
    if (dataOrganizationExerciseTempates && tab === ExerciseLibraryType.ORGANIZATION_EXERCISE) {
      setTotalEntitiesOrgLibrary(dataOrganizationExerciseTempates?.data?.totalEntities)
      const appendData: any = [...organizationExerciseTemplatesData, ...dataOrganizationExerciseTempates?.data?.entities];
      setOrganizationExerciseTemplatesData(appendData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataOrganizationExerciseTempates, searchValue])

  useEffect(() => {
    if (dataSiteExerciseTemplates && tab === ExerciseLibraryType.SITE_EXERCISE) {
      setTotalEntitiesSite(dataSiteExerciseTemplates?.data?.totalEntities)
      const appendData: any = [...siteExerciseTemplatesData, ...dataSiteExerciseTemplates?.data?.entities];
      setSiteExerciseTemplatesData(appendData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSiteExerciseTemplates, searchValue])

  useEffect(() => {
    setPage(0)
    setOrganizationPage(0)
    setSitePage(0)
    setTotalEntitiesMyLibirary(dataExerciseTemplates?.data.totalEntities);
    setTotalEntitiesOrgLibrary(dataOrganizationExerciseTempates?.data.totalEntities);
    setTotalEntitiesSite(dataSiteExerciseTemplates?.data.totalEntities);
    queryClient.removeQueries('getExerciseTemplate');
    queryClient.removeQueries('getOrganizationExerciseTemplate');
    queryClient.removeQueries('getSiteExerciseTemplate');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const fetchData = () => {
    if (tab === ExerciseLibraryType.MY_EXERCISE) {
      setPage(page + 1)

    }
    if (tab === ExerciseLibraryType.ORGANIZATION_EXERCISE) {
      setOrganizationPage(organizationPage + 1)

    }
    if (tab === ExerciseLibraryType.SITE_EXERCISE) {
      setSitePage(sitePage + 1)

    }
  }

  const exerciseItem = (item: any) => {
    return (
      <div
        className={`flex p-2 ${tempSelected?.id === item?.id ? 'bg-light-blue hover:bg-light-blue' : 'hover:bg-bg-hover'}`}
        onClick={() => handleSelectTemplate(item)}
      >
        <div>
          {(item?.videoUrl || (item?.file?.url && isVideoType(item?.file?.type))) && (
            <div className="relative w-full rounded-lg bg-black" style={{ width: 80, maxWidth: 80, maxHeight: 60 }}>
              {item?.videoUrl ? (
                <span className="absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center focus:outline-none focus:ring-4 focus:ring-gray-200">
                  <Svg name="youtube-play-icon" className="w-[25px] h-[25px]" />
                </span>
              ) : (
                <span className="absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center focus:outline-none focus:ring-4 focus:ring-gray-200">
                  <Svg name="play-icon" className="w-[25px] h-25px]" />
                </span>
              )}
              <ReactPlayer
                controls={false}
                playIcon={<button className="hidden">{''}</button>}
                playing={false}
                className="react-player rounded-lg"
                width={80}
                height={60}
                url={item?.videoUrl ? item?.videoUrl : item?.file?.url}
                light={!!item?.videoUrl}
                style={{ borderRadius: 8 }}
              />
            </div>
          )}
          {item?.file?.url && isImageType(item?.file?.type) && (
            <div className="rounded-lg border border-gray-300 relative" style={{ width: 80, height: 60 }}>
              <img className="preview object-cover rounded" style={{ width: 80, height: 60 }} src={item?.file?.url} alt="avatar" />
            </div>
          )}
          {!item?.file?.url && !item?.videoUrl && (
            <div className="rounded-lg flex justify-center items-center border relative bg-[#EDEDED]" style={{ width: 80, height: 60 }}>
              <Svg name="empty-image" className="w-[25px] h-25px]" />
            </div>
          )}
        </div>
        <div className="px-2 flex flex-col justify-center">
          <p className="font-semibold text-sm text-black">{item.name}</p>
          <p className="text-[#6B7280] font-normal text-xs line-clamp-2">{item.description}</p>
        </div>
      </div>
    );
  }
  return (
    <div ref={rootRef} >
      <Modal
        show={openModal}
        size="xl"
        root={rootRef.current ?? undefined}
        onClose={closeModal}
        dismissible={true}
        className="modal-select-exercise-template"
        ref={modalRef}
      >
        <CustomModalHeader title={t('Select Exercise Template')} toggle={closeModal} />
        <Modal.Body className="modal-body">
          <div className="flex h-34 mr-5 mb-4">
            <div
              onClick={() => handleChangeTab(ExerciseLibraryType.MY_EXERCISE)}
              className={`px-3 py-1 border-secondary cursor-pointer border rounded-l-md ${tab === ExerciseLibraryType.MY_EXERCISE ? 'bg-secondary text-white' : ''
                }`}
            >
              {t('My Library')}
            </div>
            <div
              onClick={() => handleChangeTab(ExerciseLibraryType.ORGANIZATION_EXERCISE)}
              className={`px-3 py-1 border-secondary cursor-pointer border border-l-0 border-r-0 ${tab === ExerciseLibraryType.ORGANIZATION_EXERCISE ? 'bg-secondary text-white' : ''
                }`}
            >
              {t('Organization Library')}
            </div>
            <div
              onClick={() => handleChangeTab(ExerciseLibraryType.SITE_EXERCISE)}
              className={`px-3 py-1 border-secondary cursor-pointer border rounded-r-md ${tab === ExerciseLibraryType.SITE_EXERCISE ? 'bg-secondary text-white' : ''
                }`}
            >
              {t('Hedge Library')}
            </div>
          </div>
          <div className="relative text-search mb-4">
            <span className="absolute inset-y-0 left-0 flex items-center pl-2 h-34">
              <HiOutlineSearch strokeWidth={3} className="w-4 h-4" />
            </span>
            <input
              ref={inputRef}
              className="w-full focus:oun pl-8 text-sm text-search border-1 rounded-md border-gray-300 bg-white h-34 focus:outline-none mr-5 action-search-template"
              type="search"
              name="search"
              maxLength={searchMaxLength}
              placeholder={t('Search')}
              onChange={debouncedResults}
            />
          </div>
          {tab === ExerciseLibraryType.MY_EXERCISE && exerciseTemplatesData && openModal && (
            <div id="scrollableDiv" style={{ height: 400, overflow: "auto" }}>
              <InfiniteScroll
                dataLength={exerciseTemplatesData.length}
                next={fetchData}
                hasMore={totalEntitiesMyLibirary > (page + 1) * pageCount}
                loader={<div className='flex justify-center'><Spinner /></div>}
                scrollThreshold={0.8}
                scrollableTarget='scrollableDiv'
                height={400}
              >
                {exerciseTemplatesData?.map((item: any) => exerciseItem(item)
                )}
              </InfiniteScroll>
            </div>

          )}

          {tab === ExerciseLibraryType.ORGANIZATION_EXERCISE && (
            <div id="scrollableDiv" style={{ height: 400, overflow: "auto" }}>
              <InfiniteScroll
                dataLength={organizationExerciseTemplatesData.length}
                next={fetchData}
                hasMore={totalEntitiesOrgLibrary > (organizationPage + 1) * pageCount}
                loader={<div className='flex justify-center'><Spinner /></div>}
                scrollThreshold={0.8}
                scrollableTarget='scrollableDiv'
                height={400}
              >
                {organizationExerciseTemplatesData?.map((item: any) => exerciseItem(item)
                )}
              </InfiniteScroll>
            </div>

          )}
          {tab === ExerciseLibraryType.SITE_EXERCISE && (
            <div id="scrollableDiv" style={{ height: 400, overflow: "auto" }}>
              <InfiniteScroll
                dataLength={siteExerciseTemplatesData.length}
                next={fetchData}
                hasMore={totalEntitiesSite > (sitePage + 1) * pageCount}
                loader={<div className='flex justify-center'><Spinner /></div>}
                scrollThreshold={0.8}
                scrollableTarget='scrollableDiv'
                height={400}
              >
                {siteExerciseTemplatesData?.map((item: any) => exerciseItem(item)
                )}
              </InfiniteScroll>
            </div>

          )}
          {/* {tab === ExerciseLibraryType.MY_EXERCISE && (
            <MyLibraryList tempSelected={tempSelected} setTemplateSelected={setTemplateSelected} />
          )} */}
          <GroupButton
            className="items-center justify-center pt-1 pb-2"
            buttons={[
              {
                type: 'button',
                text: t('modal.cancel'),
                classType: 'white',
                dataDismiss: 'modal',
                action: () => closeModal(),
              },
              {
                type: 'button',
                text: t('Save'),
                classType: 'blue',
                dataDismiss: 'modal',
                action: () => handleSave(),
              },
            ]}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default SelectExerciseTemplateModal;
