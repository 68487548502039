import PaginateTable from 'components/table/paginate';
import {  pageCount, roleName, routePaths } from 'utils/constants';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import NoResult from 'components/commonComponent/noResult';
import ActionTable from 'components/table/actionTable';
import './patientList.scss';
import SpinnerComponent from 'components/spinner';
import { getOrganizationUsers } from 'api/userApi';
import moment from 'moment';
import Table from 'components/table/table';
import { useDispatch, useSelector } from 'react-redux';
import { filterSelector, orgSelector, userSelector } from 'reduxs/selectors';
import { useLocation, useNavigate } from 'react-router-dom';
import LabelUser from 'components/labelUser';
import Actions from 'components/actions';
import OrganizationPatientModal from 'pages/organization/organizationPatientModal';
import ListEmpty from 'components/empty/ListEmpty';
import AssignTherapistModal from 'pages/organization/assignTherapistModal';
import {
  HiOutlineUsers
} from 'react-icons/hi';
import { IconContext } from 'react-icons'
import { filterAction } from 'reduxs/actions';

const PatientListPage = (props: any) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { userInfo } = useSelector(userSelector);
  const location = useLocation();
  const { searchValue, currentPage } = useSelector((state: any) => filterSelector(state, location.pathname));
  const { organizationId } = useSelector(orgSelector);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isMyPatients, setIsMyPatients] = useState(true)

  const { isLoading, data } = useQuery(
    ['getPatients', currentPage, searchValue, organizationId, isMyPatients],  () =>
      getOrganizationUsers({
        page: currentPage,
        limit: pageCount,
        searchQuery: searchValue,
        roleNames: roleName.PATIENT,
        ...(isMyPatients ? { therapistId: userInfo.id } : {}),

      }),
    { staleTime: Infinity },
  );

  const { data: therapistsAndOrgs, isFetching } = useQuery(
        ['getOrganizationTherapists', currentPage, searchValue, organizationId],
        () =>
            getOrganizationUsers({
                page: currentPage,
                limit: pageCount,
                searchQuery: searchValue,
                roleNames: `${roleName.ORGANIZATION_ADMIN},${roleName.THERAPIST}`,
            }),
        {
            staleTime: Infinity,
        },
    );

  const [patients, setPatients]: any = useState(data?.data?.entities);
  const [totalEntities, setTotalEntities] = useState(data?.data?.totalEntities);

  const [seletedPatient, setSeletedPatient] = useState<any | undefined>()
  const [openSetTherapistModal, setOpenSetTherapistModal] = useState<boolean>(false)

  const handleSearch = (value: string) => dispatch(filterAction.setFilter({key: location.pathname, searchValue: value}));

  useEffect(() => {
    if (data !== undefined) {
      setPatients(data?.data?.entities);
      setTotalEntities(data?.data?.totalEntities);
    }
  }, [data]);

  const handleViewDetail = (patientId: string) => {
    navigate(`${routePaths.THERAPIST_PATIENTS}/${patientId}`);
  };

  const handleSetTherapist = (patient: any) => {
    let isMyPatient = false

    patients?.map((p: any) => {
      if (patient?.id === p.id) {
        const { organizations } = p
        organizations?.map((o: any) => {
          const { therapist } = o
          if (therapist?.id === userInfo.id) {
            isMyPatient = true
          }

          return o
        })
      }

      return p
    })

    setSeletedPatient({ patient, isMyPatient })
    setOpenSetTherapistModal(true)
  }

  const handleAddPatient = () => {
    setOpenModal(true);
    setIsEdit(false);
  };

  useEffect(() => {
    dispatch(filterAction.setFilter({key: location.pathname, searchValue}))
    // Need to fetch again the patients
    queryClient.invalidateQueries('getPatients')
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isMyPatients, organizationId])



  const AddModal: any = () => {
    return (
      openModal && (
        <OrganizationPatientModal
          headerTitle={t('Invite Patient')}
          openModal={openModal}
          isEdit={isEdit}
          setOpenModal={setOpenModal}
          queryClient={queryClient}
          setCurrentPage={(value: any) => dispatch(filterAction.setFilter({key: location.pathname, searchValue, currentPage: value, }))}
          currentPage={currentPage}
        />
      )
    );
  };

  const AddSetTherapistModal: any = () => {
    return (
      openSetTherapistModal && (
        <AssignTherapistModal
          isMyPatient={seletedPatient?.isMyPatient}
          onSuccess={() => {
            setSeletedPatient(undefined)
            setOpenSetTherapistModal(false)
            queryClient.invalidateQueries('getPatients')
          }}
          openModal={openSetTherapistModal}
          patientId={seletedPatient?.patient.id || ''}
          setOpenModal={setOpenSetTherapistModal}
        />
      )
    )
  }

  return (
    <>
    <div className="w-full slp-patient px-[15px] lg:px-[30px] lg:py-[10px]">
      <div className='md:py-5'>
        <ActionTable 
          hasToggle 
          isMyPatients={isMyPatients} 
          setIsMyPatients={setIsMyPatients} 
          placeholderSearch={t('Search')} 
          buttonName={t('Invite Patient')} 
          handleSearch={handleSearch} 
          handleAddClick={handleAddPatient}
          searchValue={searchValue}
        />
      </div>
      {isLoading && totalEntities === 0 && <SpinnerComponent />}
      {(!searchValue && totalEntities === 0 && !isLoading) ? (
        <div className="w-full">
          <ListEmpty
            buttonName={t('organizationPatients.addPatient')}
            // handleAddClick={handleAddPatient}
            title={isMyPatients ? t('You currently have no Patients assigned to you') : t('titlePatientEmpty')}
            note={isMyPatients ? t('Invite a new one using the button at the top right') : t('notePatientEmpty')}
            icon={
              <IconContext.Provider value={{ color: "#D1D5DB", style: { strokeWidth: 1.5 } }}>
                <HiOutlineUsers className='h-[84px] w-auto' />
              </IconContext.Provider>
            }
          />
          {AddModal()}
        </div>
      ) :
        <>
          {totalEntities === 0 && !isLoading && <NoResult />}
          {totalEntities > 0 && (
            <>
              <div className="overflow-x-auto mb-2 list-table -mx-[15px]">
                <Table>
                  <Table.Head>
                    <Table.HeadCell className="cursor-pointer">
                      <div className="flex items-center">{t('patientName')}</div>
                    </Table.HeadCell>
                    <Table.HeadCell className="hidden-mobile-tablet">
                      <div className="flex items-center">{t('dateOfBirth')}</div>
                    </Table.HeadCell>
                    <Table.HeadCell className="hidden-mobile-tablet">
                      <div className="flex items-center">{t('therapist')}</div>
                    </Table.HeadCell>
                    <Table.HeadCell scope="col" className="py-2 w-6" />
                  </Table.Head>
                  <Table.Body>
                    {patients.map((item: any) => (
                      <Table.Row key={`row-${item.id}`} className="bg-white text-base hover:border-b text-on-primary-container">
                        <Table.Cell className="font-medium py-2.5 flex flex-row items-center" onClick={() => handleViewDetail(item.id)}>
                          <LabelUser item={item} />
                        </Table.Cell>
                        <Table.Cell className="text-sm hidden-mobile-tablet py-2.5">
                          {item.dateOfBirth ? moment(item?.dateOfBirth).format('MMM DD, YYYY') : ''}
                        </Table.Cell>
                        <Table.Cell className="text-sm hidden-mobile-tablet py-2.5">
                          <LabelUser item={item?.organizations?.[0]?.therapist} noEmail />
                        </Table.Cell>
                        <Table.Cell>
                          <Actions>
                            <Actions.Item action={() => handleViewDetail(item.id)} label={t('action.overview')} />
                            <Actions.Item action={() => handleSetTherapist(item)} label={t('organizationPatients.setTherapist')} />
                          </Actions>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
              {isLoading && <SpinnerComponent />}
              <PaginateTable 
                setCurrentPage={(value: any) => dispatch(filterAction.setFilter({key: location.pathname, searchValue, currentPage: value }))} 
                currentPage={currentPage} 
                totalEntities={totalEntities} 
              />
              {AddModal()}
              {AddSetTherapistModal()}
            </>
          )}
        </>
      }
    </div>
    </>
  );
};
export default PatientListPage;
