import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userInfo: {},
  users: [],
  isOpenProfileModal: false,
  isShowSubcribe: false,
};

const setUserInfo = (state: any, action: any) => {
  state.userInfo = action.payload;
};
const cleanUserInfo = (state: any) => {
  state.userInfo = {};
};


const setUsers = (state: any, action: any) => {
  state.users = action.payload;
};

const setOpenProfileModal = (state: any, action: any) => {
  state.isOpenProfileModal = action.payload;
}

const setIsShowSubcribe = (state: any, action: {payload: any}) => {
  state.isShowSubcribe = action.payload;
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo,
    cleanUserInfo,
    setUsers,
    setOpenProfileModal,
    setIsShowSubcribe
  },
});
export default userSlice;
