/* eslint-disable react/jsx-props-no-spreading */
import { Modal } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userAction } from 'reduxs/actions';
import { useTranslation } from 'react-i18next';
import ChangePasswordModal from 'components/modal/ChangePasswordModal';
import 'styles/styles.scss';
import { userSelector } from 'reduxs/selectors';
import CustomModalHeader from 'components/modal/customModalHeader';
import { HiOutlineUpload, HiX } from 'react-icons/hi';
import GroupButton from 'components/button/groupButton';
import { uploadFile } from 'api/fileApi';
import './sendFileModal.scss'

const SendFileModal = (props: any) => {
    const { openModalSendFile, files, setFiles, setOpenModalSendFile, mutCreateMessage, params, targetConversations } = props
    const [fileUrls, setFileUrls] = useState([])
    const rootRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const [t] = useTranslation()
    const [isUploadingFile, setIsUploadingFile] = useState(false)

    const closeModal = () => {
        setOpenModalSendFile(false)
        setFiles([])
        // const isOpenModal: any = false
        // dispatch(userAction.setOpenProfileModal(isOpenModal));
    };
    useEffect(() => {
        if (files.length > 0) {
            const fileUrls = files.map((file: any) => ({ ...file, fileUrl: URL.createObjectURL(file) }))
            setFileUrls(fileUrls)

        }
        // else closeModal()
    }, [files])

    const handleRemoveImage = (file: any) => {
        const filesTemp = files.filter((item: any) => item !== file)
        if (filesTemp.length === 0) closeModal()
        setFiles(filesTemp)

    }
    const handleSendFile = async () => {
        try {
            let fileIds: any = []
            setIsUploadingFile(true)

            for (let i = 0; i < files.length; i++) {
                const currentFileName = files[i].name
                    .split('.')
                    .map((item: string) => item.toLowerCase())
                    .join('.');
                const formData = new FormData();
                formData.append('file', files[i], currentFileName);
                const { data } = await uploadFile({ file: formData });

                if (data) {
                    fileIds.push(data.id)
                }
            }
            console.log('fileIds', fileIds);
            if (params.id && params.id !== 'new') {
                mutCreateMessage.mutate({ conversationId: targetConversations.id, fileIds });
            }
            setIsUploadingFile(false)
            closeModal()
        } catch (error) {

        }
    }

    return (
        <div ref={rootRef}>
            <Modal
                show={openModalSendFile}
                size="4xl"
                root={rootRef.current ?? undefined}
                onClose={closeModal}
                dismissible={true}
                className="send-file-modal"
            >
                <CustomModalHeader title={`${files.length} ${t('image')}${files.length > 1 ? 's' : ''} ${t('selected')}`}
                    toggle={closeModal}
                />
                <Modal.Body>
                    <div className="w-full grid grid-cols-5 gap-1 auto-rows-min mb-4 overflow-auto h-96 px-4">
                        {files.map((file: any) =>
                            <div className='relative w-full h-28 max-h-28' >
                                <span
                                    onClick={() => handleRemoveImage(file)}
                                    className="absolute rounded-full top-1 p-1 right-1 bg-white/50 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200"
                                >
                                    < HiX />
                                </span>
                                <img className="w-full h-28" src={URL.createObjectURL(file)} alt='' />
                            </div>)}
                    </div>
                    <GroupButton
                        className="items-center justify-center pt-1 pb-2"
                        buttons={[
                            {
                                type: 'button',
                                text: t('modal.cancel'),
                                classType: 'white',
                                action: () => closeModal(),
                            },
                            {
                                type: 'button',
                                text: t('modal.send'),
                                classType: 'blue',
                                isLoading: isUploadingFile,
                                action: () => handleSendFile(),

                            },
                        ]}
                    />
                </Modal.Body>
            </Modal>
        </div >)
}
export default SendFileModal