import { HiOutlineUserAdd, HiOutlineDuplicate } from 'react-icons/hi';
import PaginateTable from 'components/table/paginate';
import { apiStatus, exportFileName, defaultpageCount, status, pageType, permissionKeys, routePaths, sortByTypeType } from 'utils/constants';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getSiteUsers, getOrganizationUsers, updateActiveUserById, removeUser } from 'api/userApi';
import { initUser } from 'utils/initData';
import { useTranslation } from 'react-i18next';
import { roleType, userType } from 'utils/proptypes';
import UserModal from 'components/modal/userModal';
import NoResult from 'components/commonComponent/noResult';
import { toast } from 'react-toastify';
import SpinnerComponent from 'components/spinner';
import { find, flattenDeep, isEmpty, isEqual, map, uniq } from 'lodash';
import '../../styles/styles.scss';
import { getRoles } from 'api/rolesApi';
import { checkPermission, copyToClipboard, exportToFile, messageErrors } from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import ActionTable from 'components/table/actionTable';
import { filterSelector, orgSelector, userSelector } from 'reduxs/selectors';
import './userManagement.scss';
import Actions from '../../components/actions';
import { useLocation, useNavigate } from 'react-router-dom';
import Avatar from 'components/GroupAvatar/Avatar';
import Sort from 'components/table/sort';
import useUser from 'hooks/useUser';
import Table from 'components/table/table';
import ConfirmDeactivateModal from 'components/modal/confirmDeactivateModal';
import { filterAction } from 'reduxs/actions';

const UserManagementPage = (props: any) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { WRITE_USER, PLATFORM_ADMIN } = permissionKeys;
  const { fetchCurrentUser } = useUser();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { organizationId } = useSelector(orgSelector);
  const location = useLocation();
  const { searchValue, currentPage } = useSelector((state: any) => filterSelector(state, location.pathname));
  const [totalEntities, setTotalEntities] = useState(0);
  const [users, setUsers] = useState([initUser]);
  const [allUsers, setAllUsers] = useState([]);
  const { userInfo } = useSelector(userSelector);
  const [targetUser, setTargetUser] = useState(initUser);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const defaultRoles: [roleType] = [{ displayName: '', id: '', type: '', description: '' }];
  const [roleOptions, setRoleOptions] = useState(defaultRoles);
  const [sortBy, setSortBy] = useState('');
  const [sortByType, setSortByType] = useState('');
  const isWriteOrg = checkPermission(userInfo, props.type, [WRITE_USER], organizationId);
  const [pageCount, setPageCount] = useState(defaultpageCount);
  const [openConfirmDeactivateModal, setOpenConfirmDeactivateModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState<any>(null)
  const handleClickAddUser = () => {
    setTargetUser(initUser);
    setOpenModal(true);
    setIsEdit(false);
  };

  const getUserByRole = (currentPage: number, pageCount: number, searchValue: string) => {
    return (props.type === pageType.SITE ? getSiteUsers : getOrganizationUsers)({
      page: currentPage,
      limit: pageCount,
      searchQuery: searchValue,
      sortBy,
      sortByType,
    });
  };

  const getUsers = useQuery(
    ['getUsers', currentPage, searchValue, pageCount],
    () => getUserByRole(currentPage, pageCount, searchValue),
    {
      onSuccess: ({ data }) => {
        setUsers(data.entities);
        setTotalEntities(data.totalEntities);
      },
      onError: () => setUsers([initUser]),
      staleTime: Infinity,
    },
  );

  const getAllUsers = useMutation('getAllUsersBySite', {
    mutationFn: props.type === pageType.SITE ? getSiteUsers : getOrganizationUsers,
    onSuccess: ({ data }) => setAllUsers(data.entities),
    onError: error => {
      setAllUsers([]);
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
  });

  const { data: rolesData } = useQuery(
    ['getRoles'],
    () => getRoles({ type: props.type, limit: 0 }),
    {
      onSuccess: ({ data }) => setRoleOptions(data?.entities),
      onError: () => setUsers([initUser]),
      staleTime: Infinity,
    },
  );

  const mutRemoveUser = useMutation('removeUser', {
    mutationFn: removeUser,
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
  });

  const handleSearch = (value: any) => dispatch(filterAction.setFilter({key: location.pathname, searchValue: value}));

  const handleRemove = ({ id }: any) => {
    mutRemoveUser.mutate(id, {
      onSuccess: async () => {
        const message: string = t('userManagementPage.removeSuccessMessage');
        toast.success(message);
        await fetchCurrentUser();
        const permissionsSite = uniq(flattenDeep(map(userInfo?.roles, (r: any) => r?.permissions)));
        if (id === userInfo.id && !permissionsSite.includes(permissionKeys.PLATFORM_ADMIN)) navigate(routePaths.DASHBOARD_PAGE);
        queryClient.invalidateQueries('getOrganizationDetail');
        queryClient.invalidateQueries('getOrganizations');
        queryClient.invalidateQueries('getUsers');
      },
    });
  };

  const handleHeaderSort = (field: string) => {
    setSortBy(field);
    let type = '';
    if (field !== sortBy) {
      type = sortByTypeType.ASC;
    } else if (sortByType === sortByTypeType.ASC) {
      type = sortByTypeType.DESC;
    } else {
      setSortBy(type);
    }
    setSortByType(type);
  };

  useEffect(() => {
    setTotalEntities(getUsers.data?.data.totalEntities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    queryClient.invalidateQueries('getUsers');
    // eslint-disable-next-line
  }, [organizationId, userInfo]);

  useEffect(() => {
    if (getUsers.data !== undefined) {
      
      setUsers(getUsers.data?.data?.entities);
      setTotalEntities(getUsers.data?.data?.totalEntities);
    }
  }, [getUsers.data]);

  useEffect(() => {
    if (rolesData !== undefined) {
      setRoleOptions(rolesData?.entities);
    }
  }, [rolesData]);

  const handleActiveUser = async () => {
    const response = await updateActiveUserById(selectedUser?.id, { isActive: !selectedUser?.isActive });
    if (response.status === apiStatus.NO_CONTENT) {
      setOpenConfirmDeactivateModal(false)
      queryClient.invalidateQueries('getOrganizationDetail');
      queryClient.invalidateQueries('getOrganizations');
      queryClient.invalidateQueries('getUsers');
      queryClient.invalidateQueries('getUserDetail');
    }
  };

  const handleConfirm = (user: any) => {
      setOpenConfirmDeactivateModal(true)
      setSelectedUser(user)
  }

  const handleViewDetail = (userId: string, tab?: string) => {
    if (props.type === pageType.SITE) {
      navigate(`${routePaths.ADMIN_USERS_PAGE}/${userId}`, { state: { tab } });
    } else {
      navigate(`${routePaths.ORG_USERS_PAGE}/${userId}`, { state: { tab } });
    }
  };

  const handleCopyToClipboard = (e: any, item: any, notification: any) => {
    copyToClipboard(item, notification);
    e.stopPropagation();
  };
  const exportUserHandler = () => {
    if (users[0] !== initUser && users?.length > 0 && !getAllUsers.isLoading) {
      getAllUsers.mutate({ page: 0, limit: totalEntities, searchQuery: searchValue, sortBy, sortByType });
    }
  };

  useEffect(() => {
    if (!isEmpty(allUsers)) {
      const exportedData = allUsers.map((user: userType) => {
        return {
          'First name': user.firstName,
          'Last name': user.lastName,
          Email: user.emailAddress,
          Status: user.isActive,
        };
      });
      exportToFile(exportedData, exportFileName.USER);
    }
  }, [allUsers]);

  const renderHeader = () => {
    if (props.type === pageType.SITE) {
      return isEdit ? t('userManagementPage.editUser') : t('userManagementPage.addUser');
    } else return isEdit ? t('userManagementPage.editUserInOrg') : t('userManagementPage.addUserToOrg');
  };

  return (
    <div className="w-full user-management relative">
      <div className='py-5'>
        <ActionTable
          placeholderSearch={t('Search for users')}
          buttonName={props.type === pageType.SITE ? t('userManagementPage.addUser') : t('userManagementPage.addUserToOrg')}
          handleAddClick={isWriteOrg && handleClickAddUser}
          handleSearch={handleSearch}
          searchValue={searchValue}
        />
      </div>
      {totalEntities === 0 && !searchValue && props.type === pageType.ORGANIZATION ? (
        <div className="not-user p-8 border border-gray-200 rounded-lg bg-white mx-8">
          <div className="mb-4 flex justify-center">
            <HiOutlineUserAdd size={80} strokeWidth={1} color="#D1D5DB" fontWeight={100} />
          </div>
          <p className="text-xl font-semibold text-gray-900 text-center mb-1.5">{t('organizationPage.notification')}</p>
          <p className="text-base font-normal text-gray-900 text-center	">{t('organizationPage.note')}</p>
        </div>
      ) : (
        <>
          {!isEqual(users[0], initUser) && totalEntities === 0 && !getAllUsers.isLoading && <NoResult />}
          {!isEqual(users[0], initUser) && users?.length > 0 && (
            <div className="relative">
              <Table className="border-b table-auto">
                <Table.Head className="text-sm text-gray-600 font-semibold border-b-2 border-[#f9f5f5]">
                  <Table.HeadCell className="cursor-pointer normal-case bg-white px-[15px]" onClick={() => handleHeaderSort('firstName')}>
                    <div className="text-black font-medium text-xs flex items-center">
                      {t('userManagementPage.fullName')}
                      <Sort check={sortBy === 'firstName'} sortByType={sortByType} />
                    </div>
                  </Table.HeadCell>
                  <Table.HeadCell className="cursor-pointer hidden-mobile-tablet normal-case bg-white px-[15px]" onClick={() => handleHeaderSort('emailAddress')}>
                    <div className="text-black font-medium text-xs flex items-center">
                      {t('userManagementPage.email')}
                      <Sort check={sortBy === 'emailAddress'} sortByType={sortByType} />
                    </div>
                  </Table.HeadCell>
                  <Table.HeadCell className="hidden-mobile cursor-pointer normal-case bg-white px-[15px]" onClick={() => handleHeaderSort('isActive')}>
                    <div className="text-black font-medium text-xs flex items-center">
                      {t('userManagementPage.status')}
                      <Sort check={sortBy === 'isActive'} sortByType={sortByType} />
                    </div>
                  </Table.HeadCell>
                  {isWriteOrg && <Table.HeadCell className="cursor-pointer normal-case bg-white px-[15px]">
                    <div className="text-black font-medium text-xs flex items-center">
                      {''}
                    </div>
                  </Table.HeadCell>
                  }

                </Table.Head>
                <Table.Body>
                  {users?.map((item: userType, index) => {
                    const platformAdmin = find(uniq(flattenDeep(map(item?.roles, (r: any) => r?.permissions))), (per: any) => per === PLATFORM_ADMIN);
                    const isWriteOrgWithUser = checkPermission(userInfo, props.type, [WRITE_USER], organizationId, platformAdmin);
                    return (

                      <Table.Row onClick={() => isWriteOrgWithUser && handleViewDetail(item.id)} key={`row-${index + 1}`} className="bg-white text-base hover:bg-[#f4f8f9] border-b">
                        <Table.Cell className="py-3 px-4 flex flex-row items-center">
                          <Avatar item={item} />
                          <div className="ml-2 break-word dark:text-white font-semibold ">{`${item.firstName || ''} ${item.lastName || ''}`}</div>
                          <div className="copy-icon pl-3 cursor-pointer">
                            <HiOutlineDuplicate
                              size={25}
                              onClick={(e: any) =>
                                handleCopyToClipboard(e, `${item.firstName || ''} ${item.lastName || ''}`, t('userManagementPage.copiedName'))
                              }
                            />
                          </div>
                        </Table.Cell>
                        <Table.Cell
                          className="py-3 px-4 hidden-mobile-tablet font-medium text-gray-900 dark:text-white"
                          data-testid={`test-user-row-element-${index}`}
                        >
                          <div className="flex flex-row items-center">
                            <div>{item.emailAddress}</div>
                            <div className="copy-icon pl-3 cursor-pointer">
                              <HiOutlineDuplicate
                                size={25}
                                onClick={(e: any) => handleCopyToClipboard(e, item.emailAddress, t('userManagementPage.copiedEmail'))}
                              />
                            </div>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="hidden-mobile py-3 px-4">
                          <div className="flex flex-row items-center">
                            {item.isActive ? (
                              <span className="w-3 h-3 bg-green-400 rounded-lg mr-2 " />
                            ) : (
                              <span className="w-3 h-3 bg-red-500 rounded-lg mr-2 " />
                            )}
                            {item.isActive ? status.ACTIVE : status.INACTIVE}
                          </div>
                        </Table.Cell>
                        <Table.Cell className="py-3 flex justify-end">
                          {isWriteOrgWithUser && props.type === pageType.ORGANIZATION && (
                            <div onClick={e => e.stopPropagation()}>
                              <Actions>
                                <Actions.Item action={() => handleViewDetail(item.id)} label={t('action.viewDetails')} />
                                <Actions.Item
                                  action={() => handleViewDetail(item.id, 'assignRole')}
                                  label={t(props.type === pageType.SITE ? 'assignRoles' : 'assignRole')}
                                />
                                <Actions.Item action={() => handleViewDetail(item.id, 'organizations')} label={t('action.organizations')} />
                                {props.type === pageType.SITE && (
                                  <Actions.Item action={() => handleConfirm(item)} label={item.isActive ? t('deactivate') : t('activate')} />
                                )}
                                {props.type === pageType.ORGANIZATION && <Actions.Item action={() => handleRemove(item)} label={t('remove')} />}
                              </Actions>
                            </div>
                          )}
                          <div onClick={e => e.stopPropagation()}>
                            <Actions>
                                {props.type === pageType.SITE && (
                                  <Actions.Item action={() => handleConfirm(item)} label={item.isActive ? t('deactivate') : t('activate')} />
                                )}
                              </Actions>
                            </div>

                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
              {getUsers.isLoading && <SpinnerComponent />}
              <div className='py-8'>
                <PaginateTable
                  setCurrentPage={(value: any) => dispatch(filterAction.setFilter({key: location.pathname, searchValue, currentPage: value }))}
                  currentPage={currentPage}
                  totalEntities={totalEntities}
                  isLoadingTable={getUsers.isLoading}
                  exportHandler={exportUserHandler}
                  pageCount={pageCount}
                  setPageCount={setPageCount}
                  isShowDownload={true}
                />
              </div>
            </div>
          )}
        </>
      )}

      {openModal && (
        <UserModal
          headerTitle={renderHeader}
          isEdit={isEdit}
          openModal={openModal}
          setOpenModal={setOpenModal}
          targetData={targetUser}
          successFunc={getUsers.refetch}
          roleOptions={roleOptions}
          type={props.type}
          queryClient={queryClient}
          setIsEdit={setIsEdit}
        />
      )}
      {openConfirmDeactivateModal && (
        <ConfirmDeactivateModal selectedUser={selectedUser} openModal={setOpenConfirmDeactivateModal} setOpenModal={setOpenConfirmDeactivateModal} handleAction={handleActiveUser}/>
      )}
    </div>
  );
};
export default UserManagementPage;
