/* eslint-disable react/jsx-props-no-spreading */
import { Modal } from 'flowbite-react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/styles.scss';
import './confirmDeleteModal.scss'
import { Button } from 'flowbite-react';
import { chargeSubscription } from 'api/stripeApi';
import './subcribeModal.scss'
import { useNavigate } from 'react-router-dom';
import { roleName, routePaths, STRIPE_FIRST_USER_PRICE, STRIPE_NEXT_USER_PRICE } from 'utils/constants';
import useUser from 'hooks/useUser';
import moment from 'moment';
import { useQuery } from 'react-query';
import { getOrganizationUsers } from '../../api/userApi';
import { useSelector } from 'react-redux';
import { orgSelector, userSelector } from '../../reduxs/selectors';

const SubscriptionPaymentErrorModal = (props: any) => {
    const { openModal, subscription } = props
    const rootRef = useRef<HTMLDivElement>(null);
    const [t] = useTranslation()
    const navigate = useNavigate()
      const { fetchCurrentUser } = useUser();
  const { userInfo } = useSelector(userSelector);
   const { organizationId } = useSelector(orgSelector);
   const [totalEntities, setTotalEntities] = useState(0);
  const userOrg = userInfo?.organizations?.find((org: any) => org.organizationId === organizationId);
  const userRole = userOrg?.role?.displayName;

  useQuery(
    ['getOrganizationTherapists', organizationId],
    () =>
      getOrganizationUsers({
        page: 1,
        limit: 1000,
        roleNames: `${roleName.ORGANIZATION_ADMIN},${roleName.THERAPIST}`,
      }),
    {
      onSuccess: (data: any) => {
        setTotalEntities(data?.data?.totalEntities);
      },
      onError: () => {},
    },
  );

  const getPrice = () => {
    if(totalEntities < 2) {
      return STRIPE_FIRST_USER_PRICE;
    } else {
     return STRIPE_FIRST_USER_PRICE + (totalEntities - 1) * STRIPE_NEXT_USER_PRICE;
    }
  }

    const handleSubscription = async () => {
        try {
            const result = await chargeSubscription();
            if (result.status === 201 && result?.data?.url) window.location.replace(result.data.url);
            if (result.status === 201) {
                  navigate(routePaths.DASHBOARD_PAGE);
                  fetchCurrentUser()
            } else {
                const message: string = t('subscription.createdFail');
            }
        } catch (e) {
            const message: string = t('subscription.createdFail');
        }
    };
    return (
        <div ref={rootRef} >
            <Modal
                show={openModal}
                size="xl"
                root={rootRef.current ?? undefined}
                dismissible={true}
                className='modal-subcribe z-10'
            >

                <Modal.Body className='modal-body'>
                  <div className="p-6">
                    <p className='text-header w-full text-center font-medium text-[26px]'>
                        {t('Payment error!')}
                    </p>
                  {userRole === roleName.ORGANIZATION_ADMIN && <p className='text-center text-sm p-8'>{t('Payment process error. To ensure there is no delay in your subscription, please update your card with ')} <span className="font-bold">${getPrice()}/{t('month')}</span></p>}
                  {userRole === roleName.THERAPIST && <p className='text-center text-sm p-8'>{t('Payment process error. To ensure there is no delay in your subscription, please contact to your Organization Admin')}</p>}

                  {userRole === roleName.ORGANIZATION_ADMIN && <div className='flex justify-center items-center'>
                        <Button
                          className='subcribe-button bg-primary hover:bg-primary w-[200px] h-[44px] text-[14px]'
                          type="button"
                          size="lg"
                          onClick={() => {
                              const fetchAsync = async () => {
                                  await handleSubscription();
                              };
                              fetchAsync().catch(() => {
                              });
                          }}
                        >
                            {t('Try again')}
                        </Button>
                    </div>}
                  </div>
                </Modal.Body>
            </Modal>
        </div>)
}
export default SubscriptionPaymentErrorModal;