/* eslint-disable react/jsx-props-no-spreading */
import CustomModalHeader from '../customModalHeader';
import * as Yup from 'yup';
import GroupButton from 'components/button/groupButton';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { createRole, updateRole } from 'api/rolesApi';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { pageType, permissionKeys, roleTypeOptions } from 'utils/constants';
import './roleModal.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Label, TextInput, Tooltip, Modal, Textarea } from 'flowbite-react';
import Select from 'react-select';
import classNames from 'classnames';
import '../../form/styles.scss';
import { concat, difference, filter, find, map } from 'lodash';
import { themModal } from 'utils/theme';
import { useDispatch, useSelector } from 'react-redux';
import { filterSelector } from 'reduxs/selectors';
import { filterAction } from 'reduxs/actions';
import { useLocation } from 'react-router-dom';

const RoleModal = (props: any) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const location = useLocation();
  const { searchValue, currentPage } = useSelector((state: any) => filterSelector(state, location.pathname));
  const { PLATFORM_ADMIN } = permissionKeys;
  const { openModal, targetData, headerTitle, isEdit, handleCloseModal, permissions } = props;
  const greatestPermission = find(permissions, (perm: any) => perm === PLATFORM_ADMIN);
  const normalPermissions = filter(permissions, (perm: any) => perm !== PLATFORM_ADMIN);
  const rootRef = useRef<HTMLDivElement>(null);
  // const { permissions } = targetData;
  const [t] = useTranslation();
  const [selectedRoleType, setSelectedRoleType] = useState(null);
  const [permissioSelects, setPermissioSelects] = useState(targetData.permissions);

  const ValidateSchema = Yup.object().shape({
    displayName: Yup.string().required(t('requiredField')),
    type: Yup.string().required(t('requiredField')),
    description: Yup.string().max(1000, t('errors.descriptionLength')).nullable(),
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    reset,
    getValues,
  }: any = useForm({ mode: 'onChange', resolver: yupResolver(ValidateSchema) }) as any;

  const cancelHandler = () => {
    handleCloseModal(!openModal);
    setSelectedRoleType(null);
  };

  const handleAddAndUpdateRole = async (userPayload: any) => {
    if (isEdit) {
      await updateRole(targetData.id, userPayload);
    } else {
      await createRole(userPayload);
    }
  };

  const mutation = useMutation('create-update-role', { mutationFn: handleAddAndUpdateRole });

  const onSubmit = (value: any) => {
    mutation.mutate(value, {
      onSuccess: () => {
        cancelHandler();
        const message: string = isEdit
          ? t('roleManagementPage.RoleModal.editSuccessMessage')
          : t('roleManagementPage.RoleModal.createSuccessMessage');
        toast.success(message);
        if (currentPage !== 0 && !isEdit) {
          queryClient.invalidateQueries('getRoles', { refetchActive: false }, { cancelRefetch: true });
          dispatch(filterAction.setFilter({key: location.pathname, searchValue}))
        } else queryClient.invalidateQueries('getRoles', { refetchActive: true }, { cancelRefetch: true });
      },
      onError: async (error: any) => {
        const message: string = `${error.response.data.errors?.[0].detail}`;
        toast.error(message);
      },
    });
  };

  const renderPermission = (item: any) => {
    let type: any = getValues('type');
    const checkDisabled =
      (find(permissioSelects, perm => perm === PLATFORM_ADMIN) && item !== PLATFORM_ADMIN) ||
      (item === PLATFORM_ADMIN && type === pageType.ORGANIZATION);
    return (
      <div className="flex items-center gap-1" key={item}>
        <input
          type="checkbox"
          id={item}
          className={`custom-checkbox ${checkDisabled && 'disabled'}`}
          {...register('permissions')}
          defaultChecked={targetData.permissions.includes(item)}
          value={item}
          disabled={checkDisabled}
          onChange={({ target: { value, checked } }) => {
            let dataPermissions: any = getValues('permissions');
            dataPermissions = checked ? concat(dataPermissions, value) : difference(dataPermissions, [value]);
            setPermissioSelects(dataPermissions);
            setValue('permissions', dataPermissions);
          }}
        />
        <div className="ml-0 post-fix-width">
          <Tooltip content={item} placement="top">
            <Label className="block truncate" value={item} htmlFor={item} />
          </Tooltip>
        </div>
      </div>
    );
  };

  const handleChange = (option: any) => {
    setSelectedRoleType(option);
    if (option.value === pageType.ORGANIZATION) {
      let dataPermissions: any = getValues('permissions');
      dataPermissions = difference(dataPermissions, [PLATFORM_ADMIN]);
      setPermissioSelects(dataPermissions);
      setValue('permissions', dataPermissions);
    }
    if (option) {
      setValue('type', option?.value);
      clearErrors('type');
    } else {
      setError('type', {
        type: 'required',
        message: t('requiredField'),
      });
    }
    setValue('type', option?.value || '');
  };

  useEffect(() => {
    if (isEdit) {
      const selectedType: any = roleTypeOptions.find(role => role.value === targetData.type);
      setSelectedRoleType(selectedType);
      setValue('type', selectedType?.value);
      reset(targetData);
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={rootRef} className="role-modal-container">
      <Modal
        show={openModal}
        size="4xl"
        root={rootRef.current ?? undefined}
        popup={true}
        className="role-modal"
        onClose={cancelHandler}
        dismissible={true}
        theme={themModal}
      >
        <CustomModalHeader title={headerTitle} toggle={cancelHandler} />
        <Modal.Body theme={{ base: 'flex-12 pb-4 pt-3 px-12 overflow-auto' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4">
              <div>
                <div className="mb-2 block">
                  <Label value={`${t('roleManagementPage.RoleModal.RoleNameLabel')} *`} />
                </div>
                <div>
                  <TextInput
                    id="displayName"
                    color={errors?.displayName ? 'failure' : 'gray'}
                    type="text"
                    placeholder="Role Name"
                    {...register('displayName')}
                    helperText={<span className="text-xs">{errors?.displayName?.message}</span>}
                  />
                </div>
              </div>
              <div>
                <div className="mb-2 block">
                  <Label value={t('roleManagementPage.RoleModal.description')} />
                </div>
                <Textarea
                  color={errors?.description ? 'failure' : 'gray'}
                  id="description"
                  placeholder="Enter text for Description"
                  rows={6}
                  {...register('description')}
                  className="text-sm rounded-md"
                  helperText={<span className="text-xs">{errors?.description?.message}</span>}
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label value={`${t('roleManagementPage.RoleModal.RoleTypeLabel')} *`} />
                </div>
                <div>
                  <Select
                    id="type"
                    name="type"
                    isSearchable
                    options={roleTypeOptions}
                    className={classNames('react-select', { error: errors?.type })}
                    classNamePrefix="react-select"
                    value={selectedRoleType}
                    onChange={option => handleChange(option)}
                    noOptionsMessage={() => t('No options')}
                  />
                  {errors?.type && <div className={`text-red-600 text-xs font-normal mt-1 veri-modal height-16`}>{errors?.type?.message}</div>}
                </div>
              </div>
              <div className="grid grid-cols-4 gap-4">{greatestPermission && renderPermission(greatestPermission)}</div>
              <div className="grid grid-cols-custom gap-custom">{map(normalPermissions, (perm: any) => renderPermission(perm))}</div>
              <GroupButton
                className="items-center justify-center pt-1 pb-2"
                buttons={[
                  {
                    type: 'button',
                    text: t('modal.cancel'),
                    classType: 'white',
                    action: () => cancelHandler(),
                  },
                  {
                    type: 'submit',
                    text: t('modal.save'),
                    classType: 'blue',
                    isLoading: false,
                  },
                ]}
              />
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RoleModal;
