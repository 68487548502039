import { routePaths, DEVICE_ID } from 'utils/constants';
import { useNavigate } from 'react-router-dom';
import Svg from 'components/svg';
import InputText from 'components/inputText';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'components/button/button';
import { useMutation } from 'react-query';
import { signin } from 'api/authenApi';
import { v4 as uuidv4 } from 'uuid';
import { setToken } from 'oauth';
import useUser from 'hooks/useUser';
import { Spinner } from 'flowbite-react';
import { toast } from 'react-toastify';
import { useState } from 'react';

const LoginPage = () => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { fetchCurrentUser } = useUser();
  const [showPassword, setShowPassword] = useState(false);

  const muSignin = useMutation('signin', {
    mutationFn: signin,
    onSuccess: async data => {
      setToken(data?.data, 3600 * 24 * 30);
      await fetchCurrentUser(data.id_token);
      navigate(routePaths.DASHBOARD_PAGE);
    },
    onError: () => {
      toast.error(`${t('loginFaild')}`);
    },
  });
  const hookForm = useForm({ mode: 'onChange' }) as any;
  const errors = hookForm.formState.errors;

  const onSubmit = async (value: any) => {
    let deviceId = localStorage.getItem(DEVICE_ID);
    if (!deviceId) {
      deviceId = uuidv4();
      localStorage.setItem(DEVICE_ID, deviceId);
    }
    muSignin.mutate({ ...value, deviceId });
  };

  return (
    <div className="flex flex-1 bg-tertiaryDark h-screen items-center justify-center">
      <div className="w-[500px] px-12 py-11 bg-white rounded-md flex flex-col items-center">
        <Svg name="logo-text" />
        <p className="py-7 text-2xl font-medium">{t('LetStarted')}</p>
        <div className="w-full">
          <InputText
            hookForm={hookForm}
            rules={{ required: t('userManagementPage.requiredField') }}
            placeholder={`${t('EmailAddress')}*`}
            name="identifier"
            focus
            isHolderLabel
            rightIcon={<Svg className={errors?.identifier?.message ? `text-red-600` : 'text-gray-500'} name="email" />}
          />
          <InputText
            hookForm={hookForm}
            rules={{ required: t('userManagementPage.requiredField') }}
            placeholder={`${t('Password')}*`}
            name="password"
            focus
            isHolderLabel
            className="mt-4"
            type={showPassword ? 'text' : 'password'}
            rightIcon={<Svg className={errors?.password?.message ? `text-red-600` : 'text-gray-500'} name={showPassword ? 'eye-slash' : 'eye'} />}
            handleClick={() => setShowPassword(!showPassword)}
          />
          <div className="w-full flex justify-end pt-2 pb-6">
            <div className="text-primary text-sm font-semibold cursor-pointer" onClick={() => navigate(routePaths.FORGOT_PASSWORD)}>
              {t('ForgotPassword')}
            </div>
          </div>
          <Button
            color="primary"
            className="font-semibold w-full flex items-center justify-center py-2"
            onClick={hookForm.handleSubmit(onSubmit)}
            disabled={muSignin.isLoading}
          >
            <span className="text-base">{t('SignInText')}</span>
            {muSignin.isLoading && <Spinner size="sm" className="ml-2" />}
          </Button>
          <div className="flex justify-center text-sm pt-2">
            <span>{t('haveAccount')}</span>
            <span className="text-primary cursor-pointer pl-1 font-semibold" onClick={() => navigate(routePaths.SIGNUP_PAGE)}>
              {t('Signup')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
