import { ExerciseLibraryType } from 'utils/constants';
import { filterInterface } from './slice/filterSlice';

const userSelector = (state: any) => state.user;
const conversationSelector = (state: any) => state.conversation;
const orgSelector = (state: any) => state.org;
const uploadSelector = (state: any) => state.upload;
const filterSelector = (state: any, pathname: any): filterInterface => {
  const searchValue = state?.filter.filters[pathname]?.searchValue || '';
  const currentPage = state?.filter.filters[pathname]?.currentPage || '';
  const tab = state?.filter.filters[pathname]?.tab || ExerciseLibraryType?.MY_EXERCISE;
  return { searchValue, currentPage, tab };
};

export { userSelector, conversationSelector, orgSelector, uploadSelector, filterSelector };
