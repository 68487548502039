/*eslint-disable*/
import Routes from './router/';
import { QueryClient, QueryClientProvider } from 'react-query';
import i18next from '@core/locales';
import { I18nextProvider } from 'react-i18next';
//import { injectStyle } from 'react-toastify/dist/inject-style';
import { ToastContainer } from 'react-toastify';
import { delayShowToast, roleName } from './utils/constants';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga4';
import UpdateProfileModal from 'components/modal/updateProfileModal';
import { IntercomProvider,  } from 'react-use-intercom';
import { useEffect, useState } from 'react';
import {Intercom, shutdown} from '@intercom/messenger-js-sdk';
import { userSelector } from 'reduxs/selectors';
import { useSelector } from 'react-redux';
import { createIntercomContact, generateHmac, getIntercomContact, updateIntercomContact } from 'api/intercomApi';
import { isEmpty } from 'lodash';

function App() {

  if (typeof window !== 'undefined') {
    //injectStyle();
  }

  const isUseGoogleTracking = process.env.REACT_APP_GOOGLE_TRACKING_ID !== '';
  if (isUseGoogleTracking) ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_TRACKING_ID}`);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnMount: 'always',
        refetchOnWindowFocus: false, // default: true
      },
    },
  });

  const { userInfo } = useSelector(userSelector);
  const [hasIntercomChange, setHasIntercomChange] = useState(false)
  const isOrgAdmin = userInfo?.organizations?.find((org: any) => org?.role?.displayName === roleName.ORGANIZATION_ADMIN)
  const isTherapist = userInfo?.organizations?.find((org: any) => org?.role?.displayName === roleName.THERAPIST)
  const isPatient = userInfo?.organizations?.find((org: any) => org?.role?.displayName === roleName.PATIENT);
  const role = isOrgAdmin ? roleName.ORGANIZATION_ADMIN : isTherapist ? roleName.THERAPIST : isPatient ? roleName.PATIENT : '';
  const handleCreateContact = async () => {
    if (role) {
    const payload = {
      email: userInfo.emailAddress,
      name: `${userInfo.firstName} ${userInfo.lastName}`,
      custom_attributes: {
        user_role: role
      }
    }
    await createIntercomContact(payload).catch(()=>{})
  }
  }

  const handleGetContact = async (intercomContactId: string) => {
    const contact = await getIntercomContact(intercomContactId).catch(()=>{})
    return contact.data
  }

  useEffect(() => {
    const updateContact = async () => {
      if (role) {
      const contact = await handleGetContact(userInfo.intercomContactId).catch(()=>{})
      if (!contact) {
        await handleCreateContact()
        setHasIntercomChange(true)
      }
      if (contact?.custom_attributes?.user_role !== role && contact) {
        const payload = {
          custom_attributes: {
            user_role: role
          }
        }
          await updateIntercomContact(payload, userInfo.intercomContactId).catch(()=>{})
          setHasIntercomChange(true)
      }
      if (contact && contact?.external_id !== userInfo.id) {
        const payload = {
          external_id: userInfo.id
        }
        await updateIntercomContact(payload, contact.id).catch(()=>{})
        setHasIntercomChange(true)
      }
    }
    }
    if (!userInfo.intercomContactId && !isEmpty(userInfo)) {
      handleCreateContact()
    } 
    if  (userInfo.intercomContactId)  {
      updateContact()
    }
  }, [userInfo.intercomContactId, role])

  useEffect(() => {
    if (!isEmpty(userInfo)) {
      const isTherapist = userInfo?.organizations?.find((userOrg: any) => (userOrg?.role?.displayName === roleName.ORGANIZATION_ADMIN || userOrg?.role?.displayName === roleName.THERAPIST))
      if (userInfo.intercomContactId && isTherapist) {
        
        const handleIntercom = async () => {
          const intercomContact: any = await handleGetContact(userInfo.intercomContactId).catch(()=>{});
          const payload = {contactId : userInfo.id}
          const userHashData = await generateHmac(payload).catch(()=>{})

          const user_hash = userHashData?.data
      
          Intercom({
            app_id: process.env.REACT_APP_INTERCOM_APP_ID || '',
            user_id: userInfo.id,
            name: intercomContact?.name, 
            email: intercomContact?.email,
            user_hash: user_hash
          });
        } 
        handleIntercom()
      } 
    } else {
        shutdown()
    }

  }, [userInfo, hasIntercomChange, role])

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID || ''}>
          <I18nextProvider i18n={i18next}>
            <Routes />
            <ToastContainer
              position="top-right"
              autoClose={delayShowToast}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            <UpdateProfileModal />
          </I18nextProvider>
          {/* <Intercom appID={process.env.REACT_APP_INTERCOM_APP_ID || ''} {...user} /> */}
        </IntercomProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
