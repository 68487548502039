import { useTranslation, Trans } from 'react-i18next';
import { Modal } from 'flowbite-react';
import { useRef } from 'react';
import './organizationModal.scss';
import Button from 'components/button/button';
import { themModal } from 'utils/theme';
import CustomModalHeader from 'components/modal/customModalHeader';
import './confirmAdditionalCostModal.scss'
import GroupButton from 'components/button/groupButton';

const ConfirmRemoveUserModal = (props: any) => {
  const { openConfirmRemoveModal, setOpenConfirmRemoveModal, handleDelete, isLoading } = props;
  const [t] = useTranslation();
  const rootRef = useRef(null);

  const toggleHandler = () => {
    setOpenConfirmRemoveModal(!openConfirmRemoveModal);
  };

  return (
    <div ref={rootRef}>
      <Modal
        className="modal-confirm-additional-cost"
        show={openConfirmRemoveModal}
        size="md"
        popup={true}
        root={rootRef.current ?? undefined}
        onClose={toggleHandler}
        dismissible={true}
        theme={themModal}
      >
        <CustomModalHeader title={t('Remove User')} toggle={toggleHandler} />
        <Modal.Body className='modal-body pt-0'>
          <p className='text-center text-sm mb-5'>
            {t('Are you sure you want to remove this user?')}
          </p>
          <p className='text-center text-sm' dangerouslySetInnerHTML={{__html: t('You will receive a credit for the unused time. This credit will be applied to your next billing cycle.')}}/>
          <div className="flex justify-center items-center pt-6">
            <GroupButton
              className="items-center justify-center pb-2"
              buttons={[
                {
                  type: 'button',
                  text: t('modal.no'),
                  classType: 'white',
                  action: () => toggleHandler(),
                },
                {
                  type: 'button',
                  text: t('modal.yes'),
                  classType: 'blue',
                  action: () => handleDelete(),
                  isLoading: isLoading
                },
              ]}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default ConfirmRemoveUserModal;
