/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef, useState } from 'react';
import { optionType, roleType } from 'utils/proptypes';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { createSiteUser, createOrganizationUser, getUsersByEmail, updateSiteUser, updateOrganizationUser } from 'api/userApi';
import CustomModalHeader from './customModalHeader';
import * as Yup from 'yup';
import { filter, flattenDeep, isEmpty, map } from 'lodash';
import GroupButton from 'components/button/groupButton';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Label, TextInput, Modal } from 'flowbite-react';
import Select from 'react-select';
import classNames from 'classnames';
import { pageType } from 'utils/constants';
import { HiCheck } from 'react-icons/hi';
import './userModal.scss';
import useUser from 'hooks/useUser';
import 'styles/styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { filterSelector } from 'reduxs/selectors';
import { filterAction } from 'reduxs/actions';
import { useLocation } from 'react-router-dom';

type userOrganizationModalProps = {
  openModal: boolean,
  setOpenModal: Function,
  targetData: any,
  headerTitle: Function,
  organizationId?: string,
  successFunc?: Function,
  isEdit: boolean,
  isEditSuperAdmin?: boolean,
  roleOptions?: [roleType],
  setIsEdit?: any,
  type?: string,
  queryClient?: any,
  setCurrentPage?: any,
  currentPage?: number,
};
const UserModal = (props: userOrganizationModalProps) => {
  const { openModal, setOpenModal, targetData, headerTitle, isEdit, setIsEdit, roleOptions, type, queryClient } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const { searchValue, currentPage } = useSelector((state: any) => filterSelector(state, location.pathname));

  const [t] = useTranslation();
  const { fetchCurrentUser } = useUser();

  const rootRef = useRef(null);

  const ValidateSchema = Yup.object().shape({
    firstName: Yup.string().max(100, t('userManagementPage.nameLengthMax')).nullable().required(t('userManagementPage.requiredField')),
    lastName: Yup.string().max(100, t('userManagementPage.nameLengthMax')).nullable().required(t('userManagementPage.requiredField')),
    emailAddress: Yup.string().email(t('userManagementPage.invalidEmail')).required(t('userManagementPage.requiredField')),
    roleIds: Yup.array().min(1, t('userManagementPage.roleMin')).required(t('userManagementPage.requiredField')),
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    reset,
    getValues,
  }: any = useForm({
    mode: 'onChange',
    resolver: yupResolver(ValidateSchema),
  }) as any;

  type userType = {
    firstName: string,
    lastName: string,
    emailAddress: string,
    roleIds: string[],
  };

  const initUser: userType = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    roleIds: [],
  };

  const initOption: optionType = { value: '', label: '' };
  const [selectedOption, setSelectedOption] = useState([]);
  const roleSelectOptions: any = roleOptions?.map((r: roleType) => ({ value: r?.id, label: r?.displayName }));
  const [emailInput, setEmailInput] = useState('');

  const { data } = useQuery(['checkEmailExist', emailInput], () => getUsersByEmail(emailInput), {
    staleTime: Infinity,
    enabled: emailInput !== '',
    retry: false,
  });
  const handleSubmitUser = (data: any) => {
    if (isEdit) {
      return type === pageType.SITE ? updateSiteUser(data) : updateOrganizationUser(data);
    } else {
      return type === pageType.SITE ? createSiteUser(data) : createOrganizationUser(data);
    }
  };

  const mutation = useMutation('create-update-usesOrg', { mutationFn: handleSubmitUser });

  const onSubmit = (value: any) => {
    const payload = {
      ...value,
      firstName: isEmpty(value.firstName) ? null : value.firstName,
      lastName: isEmpty(value.lastName) ? null : value.lastName,
    };
    mutation.mutate(
      { payload, id: targetData?.id },
      {
        onSuccess: () => {
          setOpenModal(!openModal);
          const message: string = isEdit ? t('userManagementPage.editSuccessMessage') : t('userManagementPage.createSuccessMessage');
          toast.success(message);
          reset({});
          setSelectedOption([]);
          if (currentPage !== 0 && !isEdit) {
            queryClient.invalidateQueries('getUsers', { refetchActive: false }, { cancelRefetch: true });
            dispatch(filterAction.setFilter({key: location.pathname, searchValue }))
          } else queryClient.invalidateQueries('getUsers', { refetchActive: true }, { cancelRefetch: true });
          queryClient.invalidateQueries('getOrganizationDetail');
          queryClient.invalidateQueries('getOrganizations');
          queryClient.invalidateQueries('getUserDetail', { refetchActive: true }, { cancelRefetch: true });
          fetchCurrentUser();
        },
        onError: async (error: any) => {
          const message: string = `${error.response.data.errors?.[0].detail}`;
          toast.error(message);
        },
      },
    );
  };

  const cancelHandler = () => {
    reset();
    setIsEdit(false);
    setOpenModal(!openModal);
    setSelectedOption([]);
  };

  useEffect(() => {
    if (isEdit) {
      const roleIds =
        type === pageType.SITE ? targetData.roles.map((role: roleType) => role.id) : map(targetData.organizations, (o: any) => o.roleId);
      const selectedRole: any = roleSelectOptions?.filter((role: optionType) => roleIds?.find((roleId: string) => role.value === roleId)) || [
        initOption,
      ];
      setSelectedOption(selectedRole);
      const initData = {
        firstName: targetData.firstName,
        lastName: targetData.lastName,
        emailAddress: targetData.emailAddress,
        roleIds,
      };
      reset(initData);
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (options: any = []) => {
    const noDefaultOptions: any = filter(flattenDeep([options]), o => !!o);
    setSelectedOption(noDefaultOptions);
    const roleIds = noDefaultOptions.map((option: optionType) => option.value);
    setValue('roleIds', roleIds);
    if (roleIds.length > 0) {
      clearErrors('roleIds');
    } else {
      setError('roleIds', {
        type: 'required',
        message: t('userManagementPage.requiredField'),
      });
    }
  };

  useEffect(() => {
    if (selectedOption?.length > 0) {
      clearErrors('roleIds');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  useEffect(() => {
    if (data) {
      setValue('firstName', data?.data.firstName);
      setValue('lastName', data?.data.lastName);
      clearErrors('firstName');
      clearErrors('lastName');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const checkEmailIsExist = () => {
    if (type === pageType.ORGANIZATION && !errors.emailAddress) {
      const email: any = getValues('emailAddress');
      setEmailInput(email);
    }
  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      checkEmailIsExist();
    }
  };

  return (
    <div ref={rootRef}>
      <Modal
        className="modal-add-user"
        show={openModal}
        size="md"
        root={rootRef.current ?? undefined}
        onClose={() => cancelHandler()}
        dismissible={true}
      >
        <CustomModalHeader title={headerTitle()} toggle={() => cancelHandler()} />
        <Modal.Body theme={{ base: 'flex-12 pb-4 pt-3 px-12' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4">
              <div>
                <div className="mb-2 block">
                  <Label value={`${t('userManagementPage.email')} *`} />
                </div>
                <div className={`${errors.emailAddress && 'border-error'}`} onBlur={() => checkEmailIsExist()}>
                  <TextInput
                    onKeyPress={e => handleKeyPress(e)}
                    className="disabled:opacity-50"
                    id="emailAddress"
                    type="text"
                    placeholder="name@example.com"
                    {...register('emailAddress')}
                    disabled={isEdit}
                  />
                  {errors.emailAddress && (
                    <div className={`text-red-600 text-xs font-normal mt-1 veri-modal height-16`}>{errors?.emailAddress?.message}</div>
                  )}
                  {data && !errors.emailAddress && (
                    <div className="flex items-center">
                      <HiCheck className="w-6 h-6 mr-2 text-green-600 " />
                      <div className={`text-green-600 text-xs font-normal mt-1 flex`}>{t('userManagementPage.emailExist')}</div>
                    </div>
                  )}
                </div>
              </div>
              {type === pageType.SITE && (
                <>
                  <div>
                    <div className="mb-2 block">
                      <Label value={`${t('userProfilePage.firstName')} *`} />
                    </div>
                    <div className={`${errors.firstName && 'border-error'}`}>
                      <TextInput id="firstName" type="text" placeholder="Helene" {...register('firstName')} />
                      {errors.firstName && (
                        <div className={`text-red-600 text-xs font-normal mt-1 veri-modal height-16`}>{errors?.firstName?.message}</div>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="mb-2 block">
                      <Label value={`${t('userProfilePage.lastName')} *`} />
                    </div>
                    <div className={`${errors.lastName && 'border-error'}`}>
                      <TextInput id="lastName" type="text" placeholder="Engels" {...register('lastName')} />
                      {errors.lastName && (
                        <div className={`text-red-600 text-xs font-normal mt-1 veri-modal height-16`}>{errors?.lastName?.message}</div>
                      )}
                    </div>
                  </div>
                </>
              )}

              <div className="custom-multi-select">
                <div className="mb-2 block">
                  <Label value={`${t('userManagementPage.role')} *`} />
                </div>
                <div>
                  <Select
                    id="roleIds"
                    name="roleIds"
                    isMulti={type === pageType.SITE}
                    isSearchable
                    isClearable
                    options={roleSelectOptions}
                    className={classNames('react-select', { error: errors?.roleIds })}
                    classNamePrefix="react-select"
                    value={selectedOption}
                    onChange={option => handleChange(option)}
                    noOptionsMessage={() => t('No options')}
                  />
                  {errors.roleIds && <div className={`text-red-600 text-xs font-normal mt-1 veri-modal height-16`}>{errors?.roleIds?.message}</div>}
                </div>
              </div>
              <GroupButton
                className="items-center justify-center pt-1 pb-2"
                buttons={[
                  {
                    type: 'button',
                    text: t('modal.cancel'),
                    classType: 'white',
                    action: () => cancelHandler(),
                  },
                  {
                    type: 'submit',
                    text: t('modal.save'),
                    classType: 'blue',
                    isLoading: mutation.isLoading,
                  },
                ]}
              />
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default UserModal;
