import Svg from 'components/svg';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineUpload, HiX } from 'react-icons/hi';
import ReactPlayer from 'react-player';
import './fileUpload.scss'
import OpenFileModal from './openFileModal';
import { compressVideo, isVideoType } from '../../../utils/utils';
import { useDispatch } from 'react-redux';
import { uploadAction } from '../../../reduxs/actions';

const FileUpload = (props: any) => {
    const dispatch = useDispatch();
    const { handleUploadFile, isVideo = false, fileUrl, handleRemoveImage, name, index, hookForm, hasVideoUrl, isTemplate = false, key, exercise } = props;
    const [t] = useTranslation();
    const inputRef = useRef(null);
    const [openFile, setOpenFile] = useState(false)
    const [compress, setCompress] = useState(false)
    const handleClickFile = (e: any) => {
        setOpenFile(true)
        // e.stopPropagation();
    }

    const handleUpload = async (event: any) => {
        let file: any = event.target.files[0];
        if(isVideoType(file.type)) {
            setCompress(true);
            dispatch(uploadAction.setUploading(true));
            file = await compressVideo(file);
            setCompress(false);
        }

        setCompress(false);
        dispatch(uploadAction.setUploading(false));
        handleUploadFile(file);
    }

    return (
        <>
        <div className="flex w-full file-upload">
            {fileUrl !== '' && fileUrl !== null ? (
                isVideo ? (
                    <div className={`rounded-lg border border-gray-300 relative w-full aspect-video`} onClick={(e) => handleClickFile(e)}>
                        {!hasVideoUrl && (
                            <span
                                onClick={(e) => handleRemoveImage(e)}
                                className="absolute rounded-sm top-0 right-0 bg-white/50 focus:outline-none z-10 hover:bg-gray-100 focus:ring-4 focus:ring-gray-200"
                            >
                                <HiX />
                            </span>
                        )}
                        {hasVideoUrl ? <span className="absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center focus:outline-none focus:ring-4 focus:ring-gray-200">
                            <Svg name='youtube-play-icon' className={`${!isTemplate ? 'w-[50px] h-[50px]' : 'w-[50px] h-[50px]'}`} />
                        </span> :  <span className="absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center focus:outline-none focus:ring-4 focus:ring-gray-200">
                            <Svg name='play-icon' className={`${!isTemplate ? 'w-[50px] h-[50px]' : 'w-[50px] h-[50px]'}`} />
                        </span>}
                        {exercise?.file?.thumbnail && 
                        <>
                        <span
                          className="absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center focus:outline-none focus:ring-4 focus:ring-gray-200">
                            <Svg name="play-icon"
                                 className={`${!isTemplate ? 'w-[50px] h-[50px]' : 'w-[50px] h-[50px]'}`} />
                        </span>
                            <img
                              className={`preview w-full aspect-video object-cover rounded`}
                              src={exercise?.file?.thumbnail} alt="avatar" />
                        </>}
                        {!exercise?.file?.thumbnail && <ReactPlayer
                          controls={false}
                          playIcon={<button className="hidden">{''}</button>}
                          playing={false}
                          className="react-player"
                          width='100%'
                          height='100%'
                          url={fileUrl}
                          light={hasVideoUrl}
                        />}
                        </div>
                        ) : (
                        <div
                          className={`rounded-lg border border-gray-300 relative w-full aspect-video`}
                          onClick={(e) => handleClickFile(e)}>
                        <span
                            onClick={(e) => handleRemoveImage(e)}
                            className="absolute rounded-sm top-0 right-0 bg-white/50 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200"
                        >
                            <HiX />
                        </span>
                        <img className={`preview w-full aspect-video object-cover rounded`} src={fileUrl} alt="avatar" />
                    </div>
                )
            ) : (
                <div className='p-3  w-full aspect-video border border-4 rounded-[6px] border-light-blue'>    
                    <label
                    htmlFor={isTemplate ? `exercises[${index}].${name}` : `${exercise?.id}`}
                    className={`flex flex-col justify-center items-center w-full aspect-video text-gray-600 bg-[#F4F8F9] focus:outline-none hover:bg-gray-200 focus:ring-4 focus:ring-gray-200 font-normal rounded border-dotted border-2 border-primary text-xs dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700`}
                >
                    <div className="flex flex-col justify-center items-center cursor-pointer">
                        {!compress &&   <Svg name="upload-icon"
                                 className={`${!isTemplate ? 'w-[50px] h-[50px]' : 'w-[50px] h-[50px]'}`} />}
                    </div>
                    {!compress && isTemplate && <div className='text-sm font-normal text-gray-500 pt-2'>
                        {t('Upload Image/Video')}
                    </div>}
                    {compress && <div className='text-sm font-normal text-gray-500'>
                        {t('Processing...')}
                    </div>}
                    {!compress && <input ref={inputRef} id={isTemplate ? `exercises[${index}].${name}` : `${exercise?.id}`} type="file" onChange={e =>handleUpload(e)} className="hidden" />}
                </label>
            </div>
            )}
        </div>
            {/* <OpenFileModal isTemplate={isTemplate} isVideo={isVideo} hasVideoUrl={hasVideoUrl} fileUrl={fileUrl} index={index} hookForm={hookForm} openModal={openFile} setOpenModal={setOpenFile} /> */}
        </>
    );
};
export default FileUpload;
