/* eslint-disable react/jsx-props-no-spreading */
import { Modal } from 'flowbite-react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/styles.scss';
import CustomModalHeader from 'components/modal/customModalHeader';
import GroupButton from 'components/button/groupButton';
import './alertUploadingModal.scss'
import { useDispatch } from 'react-redux';
import { uploadAction } from '../../reduxs/actions';

const AlertUploadingModal = (props: any) => {
    const dispatch = useDispatch();
    const { openModal, setOpenModal, handleContinue } = props
    const rootRef = useRef<HTMLDivElement>(null);
    const [t] = useTranslation()

    const closeModal = () => {
        setOpenModal(false)
    };

    return (
        <div ref={rootRef} >
            <Modal
                show={openModal}
                size="lg"
                root={rootRef.current ?? undefined}
                onClose={closeModal}
                dismissible={true}
                className='modal-alert-uploading'
            >
                <CustomModalHeader
                    title={t('Confirm Continue')}
                    toggle={closeModal}
                />
                <Modal.Body className='modal-body'>
                    <div className='text-center mb-5 px-6 '>{t('Your video is not done processing. If you continue, it will be lost.')}</div>
                    <GroupButton
                        className="items-center justify-center pt-1 pb-2"
                        buttons={[
                            {
                                type: 'button',
                                text: t('Go Back'),
                                classType: 'white',
                                action: () => closeModal(),
                            },
                            {
                                type: 'button',
                                text: t('Continue'),
                                classType: 'blue',
                                action: () => {
                                  handleContinue();
                                  dispatch(uploadAction.setUploading(false));
                                  setOpenModal(false);
                                },

                            },
                        ]}
                    />
                </Modal.Body>
            </Modal>
        </div >)
}
export default AlertUploadingModal