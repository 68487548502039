/* eslint-disable */
import { useSelector } from 'react-redux';
import { orgSelector, userSelector } from '../reduxs/selectors';
import { Button, Spinner, TextInput } from 'flowbite-react';
import { Trans, useTranslation } from 'react-i18next';
import { HiX, HiArrowRight, HiArrowLeft, HiPlus } from 'react-icons/hi';
import OrgAlreadyModal from 'components/modal/OrgAlreadyModal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createOrganization } from 'api/organizationApi';
import { toast } from 'react-toastify';
import { messageErrors } from 'utils/utils';
import useUser from 'hooks/useUser';
import Svg from 'components/svg';
import { useState } from 'react';
import { createOrganizationUser, deleteOrganizationUser } from 'api/userApi';
import { getRoles } from 'api/rolesApi';
import { apiStatus, roleName } from 'utils/constants';
import { roleType } from 'utils/proptypes';
import { useDispatch } from 'react-redux';
import { orgAction } from 'reduxs/actions';
import './createOrganization.scss'

const CONFLICT_ERROR = "Organization has already conflicted"

const CreateOrganization = (props: any) => {
  const { setEnableCreateOrg } = props
  const [t] = useTranslation();
  const { userInfo } = useSelector(userSelector);
  const { organizationId } = useSelector(orgSelector);
  const [openOnboardingPage, setOpenOnboardingPage] = useState(false)
  const queryClient = useQueryClient()
  const dispatch = useDispatch();
  const [invitedTherapists, setInvitedTherapists] = useState<any>([])

  const { fetchCurrentUser } = useUser();
  const demoLink = process.env.REACT_APP_DEMO_LINK || ''
  const ValidateSchema = Yup.object().shape({
    name: Yup.string().required(t('userManagementPage.requiredField')).max(60, t('organizationModal.maxLength60')),
  });
  const ValidateSchemaEmail = Yup.object().shape({
    emailAddress: Yup.string().email(t('userManagementPage.invalidEmail')).required(t('userManagementPage.requiredField')),
  });

  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
    setError
  }: any = useForm({
    mode: 'onChange',
    resolver: yupResolver(ValidateSchema),
  }) as any;

  const {
    register: registerEmail,
    handleSubmit: handleSubmitInvite,
    reset: resetEmail,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ValidateSchemaEmail)
  }) as any;

  const { data: roles } = useQuery(['getOrganizationRoles'], () => getRoles({ page: 0, limit: 0 }), {
    staleTime: Infinity,
    enabled: openOnboardingPage
  });


  const handleAddTherapist = async (payload: any) => {
    const data = await createOrganizationUser({ payload });
    return data
  };
  const mutation = useMutation('organization', { mutationFn: createOrganization });

  const mutationInviteTherapist = useMutation('createOrganizationTherapist', {
    mutationFn: handleAddTherapist,
    onSuccess: () => {
      // toggleHandler()
      queryClient.invalidateQueries('getOrganizationTherapists', { refetchActive: true }, { cancelRefetch: true });
      const message = t('Therapist invited successfully')
      toast.success(message);
      fetchCurrentUser();
    },
    onError: (error) => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    }
  });
  const toggleHandler = () => {
    reset();
  };

  const onSubmitOrganization = (values: any, props: any) => {
    mutation.mutate(values, {
      onSuccess: () => {
        toggleHandler();
        const isShowOnboardingPage: any = true
        dispatch(orgAction.setIsShowOnboardingPage(isShowOnboardingPage));
        fetchCurrentUser();

        // queryClient.invalidateQueries('getOrganizations', { refetchActive: false }, { cancelRefetch: true });
        setOpenOnboardingPage(true)
        const message: string = t('organizationPage.saveSuccess');
        toast.success(message);
        // fetchCurrentUser()
      },
      onError: (error: any) => {
        if (error?.response?.data?.errors[0]?.detail === CONFLICT_ERROR) {
          const message = <Trans i18nkey={error?.response?.data?.errors[0]?.detail}>
            <p style={{ whiteSpace: 'pre-wrap' }}>
              {t(error?.response?.data?.errors[0]?.detail)}
            </p>
          </Trans>
          setError('name', {
            type: 'required',
            message: message
          });
        } else {
          const message: string = `${error.response.data.errors?.[0].detail}`;
          toast.error(message);

        }
      },
    });
  };

  const onSubmitInviteTherapist = (values: any, props: any) => {
    const isInvited = invitedTherapists.find((item: any) => item.emailAddress === values.emailAddress)
    if (isInvited) {
      const message: string = t('This user is already invited')
      toast.error(message);
    } else {
      const therapistRoleId = roles.data?.entities
        ?.filter((role: any) => role.displayName === roleName.THERAPIST).map((r: roleType) => (r.id));
      const payload = {
        ...values,
        roleIds: therapistRoleId
      }
      mutationInviteTherapist.mutate(payload, {
        onSuccess: (data) => {
          resetEmail();
          queryClient.invalidateQueries('getOrganizations', { refetchActive: false }, { cancelRefetch: true });
          setOpenOnboardingPage(true)
          setInvitedTherapists([{ id: data?.data?.id, emailAddress: data?.data?.emailAddress }, ...invitedTherapists])
          // fetchCurrentUser();
        },
        onError: error => {
          const message: string = messageErrors(error, t);
          toast.error(message);
        },
      });
    }

  };

  const handleRemoveInvitedUser = async (user: any) => {
    try {
      const data = await deleteOrganizationUser(user.id);
      if (data.status === apiStatus.NO_CONTENT) {
        const therapists = invitedTherapists.filter((item: any) => item.id !== user.id)
        setInvitedTherapists(therapists)
        const message: string = t('organizationTherapists.deletedSuccess');
        toast.success(message);
        queryClient.invalidateQueries('getOrganizationTherapists', { refetchActive: true }, { cancelRefetch: true });
        fetchCurrentUser();
      }
    } catch (e) {
      const message: string = t('generalErrors');
      toast.error(message);
    }
  }

  const handleSkipAdd = () => {
    const isShowOnboardingPage: any = false
    dispatch(orgAction.setIsShowOnboardingPage(isShowOnboardingPage));

  }

  const svgBuilding = (name: any, show: any) => (
    <Svg className="w-[20px] h-[20px]" name='building' />
  );

  const svgEmail = (name: any, show: any) => (
    <Svg className="w-[20px] h-[20px]" name='email' />
  );

  return (
    <>
      {!openOnboardingPage ? (
        <div className="h-full pt-10 flex flex-col justify-center items-center">
          <div className='flex flex-col w-1/2 mt-10 flex-row justify-center items-center p-4'>
            <p className="text-header text-[24px] font-semibold text-center mb-2">{t('Create Your Organization')}</p>
            <p className="text-header text-base font-normal line-[30px] text-center mb-2">{t('The organizations expiration is set to 14 days in the future with a free trial')}</p>
            <form className='w-full flex flex-col p-[20px] mt-10 rounded-[6px] bg-[#EDEDED]' onSubmit={handleSubmit(onSubmitOrganization)}>
              <div className='flex w-full'>
                <div className='grow'>
                  <TextInput color={errors?.name ? 'failure' : 'gray'} id="lastName" type="text" placeholder={t('Organization Name')} {...register('name')}
                    rightIcon={svgBuilding}
                  />                </div>
                <div className='ml-5'>
                  <Button type="submit" className='bg-tertiary rounded-[6px]'>{t('Save')}</Button>
                </div>
              </div>
              {errors?.name && <span className='text-xs font-normal text-red-600 pt-2'>{errors?.name?.message}</span>
              }
            </form>
            <div className='mt-10'>
              <a href={demoLink} rel="noreferrer" className="pt-4 text-tertiary text-center cursor-pointer" target="_blank">
                {t('Schedule a Demo')}
              </a>
            </div>
            <div onClick={() => setEnableCreateOrg(false)} className='bg-[#F3F4F6] flex items-center justify-center w-[236px] h-[50px] rounded-[6px] cursor-pointer mt-10'>
              <HiArrowLeft className='w-[18px] h-[30px]' color={'#6B7280'} />
              <p className='ml-3 text-gray-500'>{t('Back')}</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-full pt-10 flex flex-col justify-center items-center create-organization">
          <div className='flex flex-col w-1/2 mt-10 flex-row justify-center items-center p-4'>
            <p className="text-header text-[24px] font-semibold text-center mb-2">{t('Invite My Coworkers')}</p>
            <p className="text-header text-base font-normal line-[30px] text-center mb-2">{t('The organizations expiration is set to 14 days in the future with a free trial')}</p>
            <form className='w-full p-[20px] mt-10 rounded-[6px] bg-[#EDEDED] flex items-center justify-center' onSubmit={handleSubmitInvite(onSubmitInviteTherapist)}>
              <div className='grow'>
                <TextInput id="emailAddress" type="text" placeholder={t('Email')} {...registerEmail('emailAddress')} rightIcon={svgEmail}
                />
              </div>
              <div className='ml-5'>
                <Button type="submit" className='bg-tertiary rounded-[6px] w-[42px] h-[42px]'><HiPlus className='w-[20px] h-[20px]' /></Button>
              </div>
            </form>
            <div className='flex mt-10 w-full flex-wrap gap-[10px] justify-center items-center max-h-[400px] overflow-auto'>
              {invitedTherapists?.map((item: any) =>
                <div className='flex border border-[#E8E8E8] p-[10px] rounded-[4px] gap-2 items-center justify-center'>
                  <p>
                    {item?.emailAddress}
                  </p>
                  <div className='cursor-pointer' onClick={() => handleRemoveInvitedUser(item)}>
                    <HiX color='#6B7280' />
                  </div>
                </div>
              )}
            </div>
            <div onClick={handleSkipAdd} className='bg-tertiary flex items-center justify-center w-[211px] h-[50px] rounded-[6px] cursor-pointer mt-10'>
              <p className=' text-white text-gray-500'>{t('Complete')}</p>
              <HiArrowRight className='ml-3 w-[18px] h-[30px]' color={'white'} />
            </div>
            <div onClick={handleSkipAdd} className='mt-5 text-tertiary cursor-pointer font-semibold'>
              {t('Skip')}
            </div>
          </div>
        </div>
      )
      }
    </>

  );
};
export default CreateOrganization;
