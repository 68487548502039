import Avatar from 'components/GroupAvatar/Avatar';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import { userSelector } from 'reduxs/selectors';
import { size } from 'lodash';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Hightlight from 'components/hightlight';
import { useEffect, useState } from 'react';

const ConversationItem = props => {
  const wrapperRef = useRef(null);
  const { item, onClick } = props;
  const { userInfo } = useSelector(userSelector);
  const params = useParams();
  const messagesView = item.messages.filter(mess => !mess.isView && mess.userId !== userInfo.id);
  const sizeMess = size(messagesView);
  const messages = item.messages[0];
  const user = item.toUser.id === userInfo.id ? item.formUser : item.toUser;
  const classConversation = params.id === item.id ? 'bg-light-blue' : 'bg-bg-hover hover:bg-white';
  const [clientWidth, setClientWidth] = useState(0);

  useEffect(() => {
    if (wrapperRef?.current?.offsetWidth) setClientWidth(wrapperRef?.current?.offsetWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperRef?.current?.offsetWidth]);

  const getFullName = item => `${item?.firstName || ''} ${item?.lastName || ''}`.trim() || item?.emailAddress;

  const formatDateConversation = date => {
    if (!moment().startOf('days').diff(moment(date).startOf('days'), 'minutes')) {
      return moment(date).format('hh:mm A');
    } else if (!moment().startOf('week').diff(moment(date).startOf('week'), 'minutes')) {
      return moment(date).format('ddd');
    } else if (!moment().startOf('year').diff(moment(date).startOf('year'), 'minutes')) {
      return moment(date).format('DD/MM');
    } else {
      return moment(date).format('DD/MM/YYYY');
    }
  };

  return (
    <div
      className={`conversation-item flex items-center label-user p-4 border-b border-border cursor-pointer ${classConversation}`}
      onClick={() => onClick(item)}
      key={item.id}
      ref={wrapperRef}
    >
      <div className="flex items-center pl-2 relative">
        <div className="absolute -left-2 ">
          {sizeMess > 0 && <Hightlight />}
        </div>
      <Avatar item={user} className="w-11 h-11 text-xs" />
      </div>
      <div className="flex flex-1 items-center ml-3">
        <div className="w-full">
          <div className="flex justify-between mb-0.5 items-center name">
            {getFullName(user) && (
              <p className={`break-word text-gray-900 text-base mr-1.5 ${sizeMess > 0 ? 'font-bold' : ''}`}>{getFullName(user)}</p>
            )}
            <p className="text-gray-500 text-xs">{messages && formatDateConversation(messages?.created)}</p>
          </div>
          <div
            className="flex justify-between items-center"
            style={{ width: props.clientWidth ? props.clientWidth : !!clientWidth ? clientWidth - 90 : 'auto' }}
          >
            <p className="break-word overflow-hidden text-sm text-gray-500 font-normal text-ellipsis whitespace-nowrap">{messages?.text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversationItem;
