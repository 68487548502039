/* eslint-disable react/jsx-props-no-spreading */

import { useEffect, useState } from 'react';
import {  useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {  pageType, roleName, routePaths } from 'utils/constants';
import { toast } from 'react-toastify';
import './organizationPatientDetail.scss';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonBack from 'components/button/buttonBack';
import DetailTab from 'pages/userInfo';
import Banner from 'components/banner/banner';
import { messageErrors } from 'utils/utils';
import {  getOrganizationUsers, getUserById } from 'api/userApi';
import { getRoles } from 'api/rolesApi';
import { useSelector } from 'react-redux';
import { orgSelector } from 'reduxs/selectors';

import { TherapistType } from 'types/therapistType';

type TherapistOption = {
    therapist: TherapistType
    label: string
    value: string
}

const getFullName = (item: any) => `${item?.firstName || ''} ${item?.lastName || ''}`.trim();

const PatientDetail = (props: any) => {
    const [t] = useTranslation();
    const [patient, setPatient]: any = useState();
    const params: any = useParams();
    const { organizationId } = useSelector(orgSelector);
    const [selectedTherapist, setSelectedTherapist] = useState<TherapistOption | null>(null)
    const navigate = useNavigate()
    const [therapistOptions, setTherapistOptions] = useState<TherapistOption[]>([])
    const { data } = useQuery(['getPatient', params?.id], () => getUserById(params?.id), {
        retry: false,
        staleTime: Infinity,
        onError: error => {
            const message: string = messageErrors(error, t);
            toast.error(message);
        },
    });
    const { data: therapistsData, isFetching: isFetchingTherapist } = useQuery(
        ['getOrganizationTherapists', organizationId],
        () =>
            getOrganizationUsers({
                page: 0,
                limit: 9999,
                roleNames: `${roleName.THERAPIST}, ${roleName.ORGANIZATION_ADMIN}`,
            }),
        {
            onError: () => setTherapistOptions([]),
            staleTime: Infinity,
        },
    );

    const { data: roles } = useQuery(['getOrganizationRoles'], () => getRoles({ page: 0, limit: 0 }), {
        staleTime: Infinity,
    });

    const roleOptions: any =
        roles?.data?.entities
            ?.filter((role: any) => role.displayName === roleName.THERAPIST || role.displayName === roleName.PATIENT)

    useEffect(() => {
        if (data?.data) {

            setPatient(data?.data);
            const org = data?.data?.organizations?.find((org: any) => org.id === organizationId);
            if (org?.therapist) {
                const selectedTherapist: any = therapistOptions.find(item => item?.therapist?.id === org?.therapist?.id)
                setSelectedTherapist(selectedTherapist)
            }
        }
        // eslint-disable-next-line
    }, [data, params?.id, therapistOptions]);

    useEffect(() => {
        if (therapistsData?.data?.entities?.length) {
            const { entities } = therapistsData?.data
            const therapists: TherapistOption[] = entities.map((entity: any) => {
                const opt: any = {
                    therapist: {
                        emailAddress: entity.emailAddress,
                        firstName: entity.firstName,
                        lastName: entity.lastName,
                        image: entity.image,
                        id: entity.id,
                    },
                    label: getFullName(entity),
                    value: entity.firstName + ' | ' + entity.lastName + ' | ' + entity.emailAddress // For autocomplete search,
                }
                return opt
            })
            setTherapistOptions(therapists)
        }
    }, [therapistsData])

    const handleBack = () => {
        navigate(`${routePaths.ORG_PATIENTS}`);
    }
    

    return (
        <div className="flex flex-col patient-profile w-full h-full">
            {patient && (
                <>
                    <Banner targetData={patient} isWrite={false} labelId="Therapist ID"  handleBack={handleBack}/>
                    <div className="patient-infor h-full w-full">
                        {/* eslint-disable-next-line  */}
                        <div className="my-4">
                            <DetailTab type={pageType.ORGANIZATION} targetData={patient} isWrite={true} roleData={roleOptions} messageUpdate={t('updatedTherapist')} therapistOptions={therapistOptions} selectedTherapist={selectedTherapist} setSelectedTherapist={setSelectedTherapist} isPatientDetailSite />
                        </div>
                    </div>
                </>
            )
            }
        </div >
    );
};

export default PatientDetail;
