import { createSlice } from '@reduxjs/toolkit';

export interface filterInterface {
  searchValue: string;
  currentPage: number;
  tab?: string;
}

const initialState = {
  filters: {},
};

const setFilter = (state: any, action: { payload: any }) => {
  const newFilters = { ...state.filters };
  newFilters[action.payload.key] = action.payload;
  state.filters = newFilters;
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilter,
  },
});
export default filterSlice;
