import SidebarComponent from 'components/layouts/sidebar';
import { useSelector } from 'react-redux';
import { orgSelector, userSelector } from '../reduxs/selectors';
import { Button, Spinner, TextInput } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import Header from 'components/header';
import { roleName, routePaths } from 'utils/constants';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { HiOutlineUsers, HiArrowRight, HiArrowLeft } from 'react-icons/hi';
import OrgAlreadyModal from 'components/modal/OrgAlreadyModal';
import CreateOrganization from './createOrganization';

const DashboardPage = () => {
  const [t] = useTranslation();
  const { userInfo } = useSelector(userSelector);
  const { organizationId } = useSelector(orgSelector);

  const navigate = useNavigate();
  const organization = userInfo?.organizations?.find((o: any) => o.id === organizationId && !!o.role);
  const isTherapist = [roleName.THERAPIST, roleName.ORGANIZATION_ADMIN].includes(organization?.role?.displayName);
  const isSiteAdmin = userInfo?.roles.find((role: any) => role.displayName === roleName.SITE_ADMIN)
  const isPatient = organization?.role?.displayName === roleName.PATIENT;
  const logo = require('../../src/assets/image/svg/full-logo.svg').default;
  const therapistLogo = require('../../src/assets/image/svg/therapist.svg').default;
  const [isPatientRole, setIsPatientRole] = useState(false)
  const [isTherapistRole, setIsTherapistRole] = useState(false)
  const [enableCreateOrg, setEnableCreateOrg] = useState(false)
  const [isOrgAlready, setIsOrgAlready] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const { isShowOnboardingPage } = useSelector(orgSelector);

  useEffect(() => {
    if (isTherapist && !isShowOnboardingPage) navigate(routePaths.THERAPIST_PATIENTS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTherapist, isShowOnboardingPage]);


  useEffect(() => {
    if (isPatient) navigate(routePaths.PATIENT_DASHBOARD);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPatient, isShowOnboardingPage]);

  useEffect(() => {
  if (isSiteAdmin) navigate(routePaths.ORGANIZATION_PAGE);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSiteAdmin])

  if (!userInfo?.id) {
    return (
      <div className="text-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="w-full flex flex-row justify-center">
      {!isShowOnboardingPage &&  <SidebarComponent isDashboard={true} />}
     
      <div className="w-full h-full flex flex-col justify-between bg-gray-50">
        <div className="w-full mt-dashboard-mb shadow-md">
          <Header />
        </div>
        {userInfo?.organizations.length === 0 && !isPatientRole && !isOrgAlready && !enableCreateOrg && (
          <>
            <div className="h-full pt-28">
              <div className="mb-10 className='w-[120px] h-[120px]' flex justify-center">
                <img alt="MileMarker logo" className='w-[120px] h-[120px]' src={logo} />
              </div>
              <p className="text-header text-[24px] font-semibold text-center mb-2">{t('dashboard.notification')}</p>
              <p className="text-[22px] font-medium text-center	">{t('Please select to get started')}</p>
            </div>
            <div className="flex justify-center mt-10 flex-col items-center gap-8">
              <div onClick={() => setIsPatientRole(true)} className='px-5 flex items-center justify-between w-[300px] h-[72px] border border-[#E8E8E8] rounded-[6px] shadow-sm cursor-pointer'>
                <div className='flex items-center justify-center '>
                  <HiOutlineUsers className='w-[26px] h-[30px]' color={"#9CA3AF"} />
                  <p className='text-header font-semibold ml-3 text-[20px]'>{t('Patient')}</p>
                </div>
                <div>
                  <HiArrowRight className='w-[30px] h-[30px]' color={'#666666'} />
                </div>
              </div>
              <div onClick={() => setOpenModal(true)} className='px-5 flex items-center justify-between w-[300px] h-[72px] border border-[#E8E8E8] rounded-[6px] shadow-sm cursor-pointer'>
                <div className='flex items-center justify-center '>
                  <img alt="MileMarker logo" color='#9CA3AF' src={therapistLogo} />
                  <p className='text-header font-semibold ml-3 text-[20px]'>{t('Therapist')}</p>
                </div>
                <div>
                  <HiArrowRight className='w-[30px] h-[30px]' color={'#666666'} />
                </div>
              </div>
            </div>
          </>
        )}
        {isPatientRole && (
          <div className="h-full pt-40 flex flex-col justify-center items-center">
            <div className='flex items-center justify-center mb-10 '>
              <HiOutlineUsers className='w-[84px] h-[84px]' color={"#9CA3AF"} />
            </div>
            <p className="text-header text-[24px] font-semibold text-center mb-2">{t('Please contact your Therapist to get set up.')}</p>
            <div onClick={() => setIsPatientRole(false)} className='bg-[#F3F4F6] flex items-center justify-center w-[236px] h-[50px] rounded-[6px] cursor-pointer mt-10'>
              <HiArrowLeft className='w-[18px] h-[30px]' color={'#6B7280'} />
              <p className='ml-3 text-gray-500'>{t('Back')}</p>
            </div>
          </div>
        )}
        {isOrgAlready && (
          <div className="h-full pt-40 flex flex-col justify-center items-center">
            <div className='w-[67px] h-[91px] flex items-center justify-center mb-10 '>
              <img className='w-full h-full' alt="MileMarker logo" color='#9CA3AF' src={therapistLogo} />
            </div>
            <p className="text-header text-[24px] font-semibold text-center mb-2">{t('Please contact your Clinic Administrator to get set up.')}</p>
            <div onClick={() => setIsOrgAlready(false)} className='bg-[#F3F4F6] flex items-center justify-center w-[236px] h-[50px] rounded-[6px] cursor-pointer mt-10'>
              <HiArrowLeft className='w-[18px] h-[30px]' color={'#6B7280'} />
              <p className='ml-3 text-gray-500'>{t('Back')}</p>
            </div>
          </div>
        )}
        {!isOrgAlready && enableCreateOrg && (
            <CreateOrganization setEnableCreateOrg={setEnableCreateOrg} />
        )}
        {openModal && (
          <OrgAlreadyModal openModal={openModal} setOpenModal={setOpenModal} setIsOrgAlready={setIsOrgAlready} setEnableCreateOrg={setEnableCreateOrg} />
        )}
      </div>
      <div className="h-screen block" />
    </div>
  );
};
export default DashboardPage;
