import { configureStore } from '@reduxjs/toolkit';
import userSlice from './slice/userSlice';
import conversationSlice from './slice/conversationSlice';
import orgSlice from './slice//orgSlice';
import uploadSlice from './slice/uploadSlice';
import filterSlice from './slice/filterSlice';

export default configureStore({
  reducer: {
    user: userSlice.reducer,
    conversation: conversationSlice.reducer,
    org: orgSlice.reducer,
    upload: uploadSlice.reducer,
    filter: filterSlice.reducer
  },
});
