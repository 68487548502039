import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  organizationId: null,
  isShowPaywall: false,
  isShowOnboardingPage: false,
};

const setOrgCookie = (state: any, action: any) => {
  state.organizationId = action.payload;
};
const setPaywall = (state: any, action: { payload: any }) => {
  state.isShowPaywall = action.payload;
};
const setIsShowOnboardingPage = (state: any, action: any) => {
  state.isShowOnboardingPage = action.payload;
};
export const orgSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    setOrgCookie,
    setPaywall,
    setIsShowOnboardingPage,
  },
});

export default orgSlice;
