import axios from 'axios';
import { ORG_COOKIE_KEY, httpMethods, tokenKey } from 'utils/constants';
import urls from './urls';
import { getToken } from 'oauth';
import store from 'reduxs/store';
import { userAction } from 'reduxs/actions';
import { deleteCookie } from 'utils/cookie';

const BaseApi = () => {
  const execute = async (method: string, endpoint: string, body: object = {}) => {
    const token = getToken();
    axios.defaults.baseURL = urls.baseUrl;
    const requestConfig = {
      ...(method === httpMethods.GET && { params: body }),
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    };
    let result: any = null;
    if (method === httpMethods.GET) {
      result = axios.get(endpoint, requestConfig);
    } else if (method === httpMethods.POST) {
      result = axios.post(endpoint, body, requestConfig);
    } else if (method === httpMethods.PUT) {
      result = axios.put(endpoint, body, requestConfig);
    } else if (method === httpMethods.PATCH) {
      result = axios.patch(endpoint, body, requestConfig);
    } else {
      result = axios.delete(endpoint, requestConfig);
    }
    return result
      ?.then((response: any) => {
        return response;
      })
      .catch(async (error: any) => {
        if (error?.response?.status === 401) {
          store.dispatch(userAction.cleanUserInfo());
          deleteCookie(tokenKey);
          localStorage.removeItem(ORG_COOKIE_KEY);
        }
        throw error;
      });
  };

  return {
    methods: httpMethods,
    urls,
    execute,
  };
};

export default BaseApi;
